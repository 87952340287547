import styled from 'styled-components';

interface containerInterface {
  error: boolean;
}

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ff0000;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    ::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  & input:checked + span {
    background-color: #76ee59;
  }

  & input:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  & input:checked + span:before {
    ::-webkit-transform {
      transform: translateX(26px);
    }
    ::-ms-transform {
      transform: translateX(26px);
    }
    transform: translateX(26px);
  }
`;
