import api from '../services/api';

interface ISensorData {
  id: string;
  sensor_id: string;
  value: number;
  timestamp: string;
  created_at: string;
  updated_at: string;
}
export const loadData = async (
  setChartData: any,
  addToast: any,
  selectedSensorId: string,
  initialDate: string,
  finalDate: string,
  max: number,
  setLoading?: any,
) => {
  setLoading && setLoading(true);

  try {
    const { data } = await api.get<ISensorData[]>(`sensorData`, {
      params: { sensor_id: selectedSensorId, date: initialDate, final_date: finalDate, max },
    });

    data.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    setChartData(data);
  } catch (error) {
    addToast({
      type: 'error',
      title: 'Erro ao buscar pesquisar por essa data!',
      description: `${error}`,
    });
  } finally {
    setLoading && setLoading(false);
  }
};
