import React, { InputHTMLAttributes, useRef, useEffect } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  hideErrorIcon?: boolean;
}

const DateInput: React.FC<DateInputProps> = ({ name, color, hideErrorIcon, size, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {!hideErrorIcon && error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
      <Container error={!!error} className="container-input">
        <input {...rest} ref={inputRef} type="date" />
      </Container>
    </>
  );
};
export default DateInput;
