import React, { ButtonHTMLAttributes, useRef, useCallback, useState, useMemo, useEffect } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';

import Button from '../../../../../components/Button';

import { Container } from './styles';

import { useSelectInfo } from '../../../../../hooks/selectInfo';
import { useToast } from '../../../../../hooks/toast';
import { IMessage, UserRolesEnum, useAuth } from '../../../../../hooks/auth';

import api from '../../../../../services/api';

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  show?: boolean;
  closeFunction: (arg0: boolean) => void;
  messages: IMessage[];
  setMessages: (messages: IMessage[]) => void;
}

const Message: React.FC<InputProps> = ({ show, closeFunction, messages, setMessages }) => {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const { selectedUser, selectUser } = useSelectInfo();
  const { addToast } = useToast();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [emptyFIeld, setEmptyFIeld] = useState(false);

  const readMessage = useCallback(async () => {
    try {
      await api.put(`message/${messages[0].id}`);

      const messagesToUpdate = messages;

      messagesToUpdate.shift();

      setMessages([...messagesToUpdate]);

      return closeFunction(false);
    } catch (error) {
      return addToast({
        type: 'error',
        title: 'Erro ao enviar informações',
        description: error.response ? `${error.response.data.message}` : `${error}`,
      });
    }
  }, [addToast, selectedUser, closeFunction, selectUser, messages]);

  const submitMessage = useCallback(
    async e => {
      setLoading(true);
      e.preventDefault();
      setEmptyFIeld(false);

      const message = textRef.current?.value;
      const selectedDevice = selectRef.current?.value;

      if (!message) {
        setLoading(false);
        setEmptyFIeld(true);
        return addToast({
          type: 'error',
          title: 'Erro ao enviar a mensagem',
          description: 'Descreva o motivo de sua mensagem',
        });
      }

      try {
        e.persist();
        await api.post('message', { message, selectedDevice });

        e.target.reset();
        closeFunction(false);
        return addToast({
          type: 'success',
          title: 'Mensagem enviada!',
          description: 'Aguarde até que um administrador faça contato',
        });
      } catch (error) {
        return addToast({
          type: 'error',
          title: 'Erro ao enviar mensagem',
          description: error.response ? `${error.response.data.message}` : `${error}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, closeFunction],
  );

  const messageInformation = useMemo(() => {
    let tittle;
    let device;
    let message;
    let submit;

    if (user.role === UserRolesEnum.ADMIN) {
      if (selectedUser?.devices?.length) {
        const filteredDevice = selectedUser?.devices && selectedUser.devices.filter(userDevice => userDevice.id === messages[0]?.device_id);

        tittle = <h2>Mensagem do Usuario</h2>;
        device = <span>{filteredDevice[0]?.name}</span>;
        message = <span>{messages[0]?.message}</span>;
        submit = (
          <Button type="button" onClick={readMessage}>
            fechar
          </Button>
        );
      }
    } else {
      tittle = <h2>Escreva uma mensagem</h2>;
      device = (
        <select name="selectedDevice" ref={selectRef}>
          {selectedUser.devices &&
            selectedUser.devices.map(userDevice => (
              <option value={userDevice.id} key={userDevice.id}>
                {userDevice.name}
              </option>
            ))}
        </select>
      );
      message = <textarea name="message" id="message" ref={textRef} />;
      submit = <Button type="submit">{loading ? 'enviando...' : 'enviar'}</Button>;
    }

    return (
      <>
        {user.role !== UserRolesEnum.ADMIN && (
          <button className="closeMessageButton" type="button" onClick={() => closeFunction(false)}>
            <IoMdCloseCircle />
          </button>
        )}
        {tittle}
        <form onSubmit={submitMessage} id="form">
          <p>Dispositivo:</p>
          {device}
          <p>Mensagem:</p>
          {message}
          {submit}
        </form>
      </>
    );
  }, [closeFunction, loading, selectedUser, submitMessage, user, readMessage, messages]);

  return (
    <Container show={show} isEmpty={emptyFIeld} sending={loading}>
      {messageInformation}
    </Container>
  );
};

export default Message;
