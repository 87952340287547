import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface INoNumberSelector {
  isSelected: boolean;
}

interface IInformationsContainer {
  active: string;
}

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 100%;
    overflow: unset;
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const InformationsContainer = styled.div<IInformationsContainer>`
  width: 30%;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  align-items: center;
  flex-direction: column;
  & div.editUserAvatar {
    position: relative;
    & div.editUserAvatarImage {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 0 0 4px 0px #e0e0e0;
      display: flex;
      justify-content: center;
      & img {
        height: 100%;
      }
      & svg {
        color: #0c5c75;
        font-size: 6em;
        margin-top: 0.3em;
      }
    }

    & div.editUserAvatarButton {
      position: absolute;
      right: 5%;
      bottom: 0%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #0c5c75;
      border: 2px solid #0c5c75;
      display: flex;
      align-items: center;
      justify-items: center;
      cursor: pointer;
      & svg {
        font-size: 1.5em;
        color: #fff;
        margin: 0 auto;
      }
      &:hover {
        background: ${shade(0.3, '#0c5c75')};
        & svg {
          color: #fff;
        }
      }
    }
  }

  & > ul {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 1px #e0e0e0;
    width: 60%;
    & li {
      & button {
        width: 100%;
        text-align: start;
        padding: 15px 20px;
        background-color: #fff;
        color: #b9b9b9;

        &:hover {
          color: #0c5c75;
        }
      }
      & button.active {
        color: #0c5c75;
        background-color: #f7f7f7;
      }
      & + li {
        border-top: 1px solid #e0e0e0;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    margin-bottom: 15px;

    & div.editUserAvatar {
      & div.editUserAvatarImage {
        width: 80px;
        height: 80px;
        margin-right: 5px;
      }

      & div.editUserAvatarButton {
        right: 5%;
        bottom: 0%;
        width: 20px;
        height: 20px;
        & svg {
          font-size: 1.2em;
        }
      }
    }

    & > ul {
      border-radius: 5px;

      & li {
        & button {
          width: 100%;
          padding: 10px;
        }
      }
    }
  }
`;

export const UpdateFormContainer = styled.div`
  max-width: 800px;
  padding: 30px 10px 30px 45px;
  box-shadow: -8px 0px 10px 0px #e0e0e0;
  flex: 1;
  & form {
    border-radius: 15px;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0px 0px 10px 1px #e0e0e0;
    & > h2 {
      text-align: start;
      width: 100%;
      color: #0c5c75;
      margin: 10px 0;
    }

    & p {
      margin: 5px 0;
      color: #0c5c75;
      font-size: 0.9em;
    }

    & input {
      font-weight: 400;
    }

    & div.disabled {
      div {
        background: #ececec;
        border: 2px solid #ececec;
        cursor: not-allowed;
        input {
          color: #b9b9b9;
          cursor: not-allowed;
        }
      }
    }

    & button {
      width: 100%;
      margin-top: 30px;
    }
  }

  @media (max-width: 1300px) {
    width: 65%;
  }

  @media (max-width: 1023px) {
    width: 70%;
    padding: 30px 10px 30px 45px;
    box-shadow: -8px 0px 10px 0px #e0e0e0;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 5px;
    box-shadow: none;
    padding-bottom: 65px;

    & form {
      border-radius: 10px;
      padding: 15px;
      & > h2 {
        margin: 7px 0;
        font-size: 1em;
      }

      & p {
        margin: 0 0 5px 0;
      }

      & button {
        margin-top: 15px;
      }
    }
  }
`;

export const NoNumberSelector = styled.div<INoNumberSelector>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  width: 100%;
  cursor: pointer;

  & > svg {
    transition: 0.3s;
    font-size: 1.8em;
    margin-right: 12px;
    ${props =>
      props.isSelected
        ? css`
            color: #0c5c75;
            box-shadow: inset 0px 0px 0px 5px #0c5c75;
          `
        : css`
            color: white;

            box-shadow: 0px 0px 10px 1px #e0e0e0;
            border-radius: 5px;
          `}
  }

  & > p {
    font-size: 0.9em;
  }

  @media (max-width: 768px) {
    padding-bottom: 5px;

    & > p {
      font-size: 0.8em;
    }
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: #0000004d;
  display: flex;
  align-items: center;
  justify-content: center;
  div.container {
    width: 80%;
    max-width: 500px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    & svg {
      font-size: 2em;
      cursor: pointer;
      color: #b9b9b9;
      :hover {
        color: #0c5c75;
      }
    }

    & div.fileName {
      font-weight: bold;
      padding: 15px;
      span {
        font-size: 0.7em;
        font-weight: lighter;
      }
    }

    & button {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    & div.container {
      & svg {
        font-size: 1.7em;
      }
    }
  }
`;
