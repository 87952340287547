import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { UserRolesEnum, useAuth } from '../../../hooks/auth';

import Dashboard from '../pages/Dashboard';
import User from '../pages/User';
import AdminUser from '../pages/AdminUser';
import Devices from '../pages/Devices/routes/index';

const Routes: React.FC = () => {
  const { user } = useAuth();

  return (
    <Switch>
      <Route path="/app/user" exact component={user.role !== UserRolesEnum.OPERATOR ? AdminUser : User} />
      <Route path="/app/dashboard" exact component={Dashboard} />
      <Route path="/app/device*" exact component={Devices} />
    </Switch>
  );
};

export default Routes;
//
