import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Grid from '@material-ui/core/Grid';

import { RiCheckboxFill, RiCloseCircleFill, RiCheckboxBlankLine } from 'react-icons/ri';

import PageTitle from '../../../../components/PageTitle';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';

import getValidationErrors from '../../../../utils/getValidationsErrors';
import api from '../../../../services/api';

import * as S from './styles';

interface IViaCEPResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

interface IFile {
  file: File;
  name: string;
  readableSize: string;
}

const User: React.FC = () => {
  const addressFormRef = useRef<FormHandles>(null);
  const personalFormRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [noNumber, setNoNumber] = useState(user.addressUser?.number === 'N/A');
  const [editInformationType, setEditInformationType] = useState('address');
  const [loader, setLoader] = useState(false);
  const [showUploadedFilesContainer, setShowUploadedFilesContainer] = useState(false);

  const findCEPHandler = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      if (value.length !== 8) {
        return;
      }

      try {
        const { data } = await axios.get<IViaCEPResponse>(`https://viacep.com.br/ws/${value}/json/`);

        addressFormRef.current?.setData({ cep: value, city: data.localidade, district: data.bairro, uf: data.uf, street: data.logradouro });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao buscar cep',
          description: `${error}`,
        });
      }
    },
    [addToast],
  );

  const addressFormHandler = useCallback(
    async formData => {
      setLoader(true);

      try {
        addressFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          uf: Yup.string(),
          cep: Yup.string().required(),
          city: Yup.string(),
          complement: Yup.string(),
          district: Yup.string(),
          number: Yup.string(),
          street: Yup.string(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        setNoNumber(formData.number === 'N/A');

        const newUser = {
          ...user,
          addressUser: {
            ...user.addressUser,
            cep: formData.cep,
            city: formData.city,
            complement: formData.complement,
            district: formData.district,
            number: formData.number,
            street: formData.street,
            uf: formData.uf,
          },
        };

        await api.put('/profiles/address', { ...formData, id: user.addressUser.id });

        await updateUser(newUser);

        return addToast({
          type: 'success',
          title: 'Endereço salvo',
          description: 'Seu endereço foi salvo com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return addressFormRef.current?.setErrors(errors);
        }

        return addToast({
          type: 'error',
          title: 'Erro ao atualizar o endereço',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoader(false);
      }
    },
    [addToast, updateUser, user],
  );

  const personalFormHandler = useCallback(
    async formData => {
      setLoader(true);

      try {
        personalFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().email().required(),
          name: Yup.string(),
          oldPassword: Yup.string().when('password', {
            is: val => !!val.length,
            then: Yup.string().required('Campo obrigatório'),
            otherwise: Yup.string(),
          }),
          password: Yup.string(),
          password_confirmation: Yup.string()
            .when('password', {
              is: val => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password')], 'Confirmação incorreta'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const newUser = {
          ...user,
          name: formData.name,
        };

        const { name, email, oldPassword, password, password_confirmation } = formData;

        const dataToSend = {
          name,
          email,
          ...(password && {
            oldPassword,
            password,
            password_confirmation,
          }),
        };

        await api.put('/profiles', dataToSend);

        personalFormRef.current?.setFieldValue('oldPassword', '');
        personalFormRef.current?.setFieldValue('password', '');
        personalFormRef.current?.setFieldValue('password_confirmation', '');

        await updateUser(newUser);

        return addToast({
          type: 'success',
          title: 'Perfil atualizado',
          description: 'Seu perfil foi atualizado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return personalFormRef.current?.setErrors(errors);
        }

        return addToast({
          type: 'error',
          title: 'Erro ao atualizar perfil',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoader(false);
      }
    },
    [addToast, updateUser, user],
  );

  const noNumberHandle = useCallback(() => {
    if (!noNumber) {
      addressFormRef.current?.setData({
        number: 'N/A',
      });
    }

    setNoNumber(!noNumber);
  }, [noNumber]);

  const editUserAvatarHandle = useCallback((): void => {
    if (!showUploadedFilesContainer) {
      setUploadedFiles([]);
    }

    setShowUploadedFilesContainer(!showUploadedFilesContainer);
  }, [showUploadedFilesContainer]);

  const handleUpload = useCallback(async (): Promise<void> => {
    setLoader(true);

    const data = new FormData();

    if (!uploadedFiles.length) return;

    const file = uploadedFiles[0];

    data.append('file', file.file, file.name);

    try {
      const response = await api.patch('/profiles/avatar', data);

      await updateUser(response.data);

      addToast({
        type: 'success',
        title: 'Foto atualizada',
        description: 'Sua imagem de avatar foi atualizada!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar perfil',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    } finally {
      setLoader(false);
      setShowUploadedFilesContainer(!showUploadedFilesContainer);
    }
  }, [uploadedFiles, addToast, showUploadedFilesContainer, updateUser]);

  const addressForm = useMemo(() => {
    return (
      <Form ref={addressFormRef} id="address" onSubmit={addressFormHandler} initialData={user.addressUser}>
        <h2>Endereço</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <p>Cep</p>
            <Input name="cep" type="text" onChange={findCEPHandler} defaultValue={user.addressUser.cep} />
          </Grid>
          <Grid item xs={12} sm={9} className="disabled">
            <p>Rua</p>
            <Input name="street" type="text" defaultValue={user.addressUser.street} />
          </Grid>
          <Grid item xs={5} sm={2} className={noNumber ? 'disabled' : ''}>
            <p>Número</p>
            <Input name="number" type="text" disabled={noNumber} defaultValue={user.addressUser.number} />
          </Grid>
          <Grid item container alignItems="flex-end" xs={7} sm={4}>
            <S.NoNumberSelector onClick={noNumberHandle} isSelected={noNumber}>
              <RiCheckboxFill />
              <p>Sem número</p>
            </S.NoNumberSelector>
          </Grid>
          <Grid item xs={12} sm={6}>
            <p>Complemento</p>
            <Input name="complement" type="text" defaultValue={user.addressUser.complement} />
          </Grid>
          <Grid item xs={12} sm={3} className="disabled">
            <p>Bairro</p>
            <Input disabled name="district" type="text" defaultValue={user.addressUser.district} />
          </Grid>
          <Grid item xs={12} sm={7} className="disabled">
            <p>Cidade</p>
            <Input disabled name="city" type="text" defaultValue={user.addressUser.city} />
          </Grid>
          <Grid item xs={12} sm={2} className="disabled">
            <p>UF</p>
            <Input disabled name="uf" type="text" defaultValue={user.addressUser.uf} />
          </Grid>
        </Grid>
        <Grid>{loader ? <Button>Enviando ...</Button> : <Button>Salvar</Button>}</Grid>
      </Form>
    );
  }, [addressFormHandler, findCEPHandler, noNumber, noNumberHandle, user, loader]);

  const personalForm = useMemo(() => {
    return (
      <Form ref={personalFormRef} onSubmit={personalFormHandler} initialData={user}>
        <h2>Meu acesso</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <p>Nome</p>
            <Input name="name" type="text" defaultValue={user.name} />
          </Grid>
          <Grid item xs={12} className="disabled">
            <p>Email</p>
            <Input name="email" type="text" disabled defaultValue={user.email} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <p>Senha Atual</p>
            <Input name="oldPassword" type="password" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <p>Nova Senha</p>
            <Input name="password" type="password" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <p>Repita nova senha</p>
            <Input name="password_confirmation" type="password" />
          </Grid>
        </Grid>
        <Grid>{loader ? <Button>Enviando ...</Button> : <Button>Salvar</Button>}</Grid>
      </Form>
    );
  }, [user, loader, personalFormHandler]);

  return (
    <>
      <PageTitle sub="Perfil" title="Editar" />
      <S.Container>
        <S.InformationsContainer active={editInformationType}>
          <ul>
            <li>
              <button className={editInformationType === 'address' ? 'active' : ''} type="button" onClick={() => setEditInformationType('address')}>
                Endereço
              </button>
            </li>
            <li>
              <button className={editInformationType === 'personal' ? 'active' : ''} type="button" onClick={() => setEditInformationType('personal')}>
                Minhas informações
              </button>
            </li>
          </ul>
        </S.InformationsContainer>

        <S.UpdateFormContainer>
          {editInformationType === 'address' ? addressForm : ''}

          {editInformationType === 'personal' ? personalForm : ''}
        </S.UpdateFormContainer>

        {showUploadedFilesContainer && (
          <S.Backdrop>
            {
              <div className="container">
                <Grid container spacing={2}>
                  <Grid item container justify="flex-end" xs={12}>
                    <RiCloseCircleFill onClick={editUserAvatarHandle} />
                  </Grid>

                  <Grid item xs={12}>
                    {!!uploadedFiles &&
                      uploadedFiles.map(file => (
                        <div className="fileName" key={file.name}>
                          {file.name}
                          <br />
                          <span>{file.readableSize}</span>
                        </div>
                      ))}
                  </Grid>

                  <Grid item xs={12}>
                    <Button onClick={handleUpload}>{loader ? 'Enviando ...' : 'Salvar'}</Button>
                  </Grid>
                </Grid>
              </div>
            }
          </S.Backdrop>
        )}
      </S.Container>
    </>
  );
};

export default User;
