import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #0c5c75;
  margin-bottom: 10px;

  & div.title {
    display: flex;
    align-items: center;
    & div {
      width: 5px;
      border-radius: 2.5px;
      background-color: #00dbfc;
      height: 100%;
      margin-right: 0.7em;
    }
    & p {
      font-size: 1em;
      strong {
        font-size: 1.5em;
        font-weight: 600;
      }
    }
  }
`;
