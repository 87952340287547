import React, { InputHTMLAttributes, useRef, useEffect, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  hideErrorIcon?: boolean;
  containerClassName?: string;
  showPercentage?: boolean;
}

const Input: React.FC<InputProps> = ({ name, icon: Icon, color, hideErrorIcon, size, type, containerClassName, showPercentage = false, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [inputType, setInputType] = useState(type || 'text');

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const Eye = useCallback(
    () => (
      <div className="eye">
        {inputType === 'password' ? <FiEyeOff onClick={() => setInputType('text')} /> : <FiEye onClick={() => setInputType('password')} />}
      </div>
    ),
    [inputType],
  );

  return (
    <>
      {!hideErrorIcon && error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
      <Container error={!!error} className={`container-input ${containerClassName}`}>
        {Icon && <Icon color={color} size={size} />}
        <input {...rest} ref={inputRef} type={inputType} />
        {showPercentage && <span>%</span>}

        {type === 'password' && <Eye />}
      </Container>
    </>
  );
};
export default Input;
