import React, { createContext, useCallback, useState, useContext } from 'react';
import { IUser } from './auth';

interface DeviceContextData {
  selectedUser: IUser;
  selectedDeviceId: string;
  selectedSensorId: string;
  selectDevice(data: string): Promise<void>;
  selectSensor(data: string): Promise<void>;
  selectUser(user: IUser): Promise<void>;
}

const SelectInfoContext = createContext<DeviceContextData>({} as DeviceContextData);

const SelectInfoProvider: React.FC = ({ children }) => {
  const [selectedSensorId, setSelectedSensorId] = useState(() => {
    return '';
  });

  const [selectedDeviceId, setSelectedDeviceId] = useState(() => {
    return '';
  });

  const [selectedUser, setSelectedUser] = useState<IUser>(() => {
    return {} as IUser;
  });

  const selectSensor = useCallback(async (sensorId: string) => {
    setSelectedSensorId(sensorId);
  }, []);

  const selectDevice = useCallback(async (deviceId: string) => {
    setSelectedDeviceId(deviceId);
  }, []);

  const selectUser = useCallback(async (selectuser: IUser) => {
    setSelectedUser(selectuser);
  }, []);

  return (
    <SelectInfoContext.Provider
      value={{
        selectedUser,
        selectedDeviceId,
        selectedSensorId,
        selectDevice,
        selectUser,
        selectSensor,
      }}
    >
      {children}
    </SelectInfoContext.Provider>
  );
};

function useSelectInfo(): DeviceContextData {
  const context = useContext(SelectInfoContext);

  if (!context) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }

  return context;
}

export { SelectInfoProvider, useSelectInfo };
