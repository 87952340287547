import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

interface IContainer {
  show: boolean;
}

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-80px)

  }
  to {
    opacity: 1;
    transform: translateX(0px)

  }
`;

export const Container = styled.div`
  position: relative;
  box-shadow: inset 0px 0px 10px 1px #cacaca;
  height: 100dvh;
  animation: ${appear} 0.6s;
  background-color: #e3eff4;
  padding-right: 30px;
  z-index: 350;
  @media (max-width: 768px) {
    position: fixed;

    margin-left: -1.5em;
    animation: ${appear} 0.6s;
    padding-right: 0px;
  }
`;

export const Content = styled.div<IContainer>`
  width: 0px;
  z-index: 300;
  padding-left: 30px;
  opacity: 0;
  transition: 0.6s;
  visibility: hidden;
  overflow: hidden;

  ${props =>
    props.show &&
    css`
      width: 280px;
      opacity: 1;
      visibility: visible;
    `}

  & > h2 {
    color: #0c5c75;
    font-size: 1em;
    font-weight: 600;
    padding-top: 60px;
    &::before {
      content: '';
      padding: 0 1px;
      margin-right: 7px;
      background-color: #0c5c75;
    }
  }

  & h3 {
    color: #0c5c75;
    font-size: 0.9em;
    & svg {
      font-size: 1em;
      margin-right: 5px;
    }
    & span {
      font-weight: 300;
    }
  }

  & > form {
    width: 100%;
    margin-top: 20px;

    & ul {
      color: #0c5c75;
      font-size: 0.8em;
      margin: 10px 0;
      display: flex;
      & button {
        background: none;
        width: 50%;

        & li {
          color: #0c5c75;
          width: 100%;
          font-weight: 400;
          padding: 3px 0px;
          text-align: center;
          font-size: 0.8em;
          &:hover {
            font-weight: 600;
            cursor: pointer;
          }
        }
        & li.active {
          border-bottom: 1px solid #0c5c75;
        }
      }
    }

    & > div {
      padding: 0;
      border: 2px solid #0c5c75;
      background-color: transparent;
      box-shadow: none;
      margin-top: 20px;
      & input {
        font-weight: 400;
        font-size: 0.9em;
        padding: 5px 10px;
        background-color: transparent;
        color: #0c5c75;
      }
    }

    & > button {
      font-size: 0.8em;
      width: 70%;
      padding: 10px;
      margin: 15px auto;
      display: block;
      background-color: #0c5c75;

      &:hover {
        background: ${shade(0.2, '#0c5c75')};
      }
    }
  }

  & > div.results {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    & > ul {
      width: 100%;
      overflow: auto;
      max-height: 500px;
      & button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #e1e1e1;
        padding: 5px;
        margin-top: 15px;
        width: 100%;
        background: none;
        text-align: start;
        & div {
          display: flex;
          color: #0c5c75;
          font-size: 0.9em;
          & div.messageIconContainer {
            border: 2px solid #a7d1e1;
            width: 2em;
            height: 2em;
            border-radius: 50%;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          & p {
            & small {
              font-size: 0.7em;
            }
          }
          & svg {
            font-size: 1.2em;
            color: #a7d1e1;
          }
        }

        &:hover {
          font-weight: 400;
          cursor: pointer;
        }
      }
      & button.active {
        border-bottom: 2px solid #0c5c75;
        & div {
          & svg {
            color: #0c5c75;
          }
        }
        & div.messageIconContainer {
          border: 2px solid #0c5c75;
        }
      }

      ::-webkit-scrollbar {
        width: 5px;
      }
      ::-webkit-scrollbar-track {
        background: #a7d1e1;
      }
      ::-webkit-scrollbar-thumb {
        background: #0c5c75;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #024559;
      }
    }
  }

  @media (max-width: 768px) {
    width: 0;
    padding-left: 0px;
    ${props =>
      props.show &&
      css`
        padding: 0 15px;
        width: 250px;
        opacity: 1;
        visibility: visible;
      `};
  }
`;

interface TriangleProps {
  isShow: boolean;
}

export const Triangle = styled.div<TriangleProps>`
  background-color: #e3eff4;
  text-align: left;
  z-index: 200;
  position: absolute;
  box-shadow: inset -6px 5px 6px 0px #e1e1e1;
  top: 45%;
  right: -1em;
  width: 2em;
  height: 2em;
  border-top-right-radius: 30%;
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
  cursor: pointer;
  &:hover {
    box-shadow: inset -6px 5px 6px 0px #c1c1c1;
  }
  @media (max-width: 500px) {
    right: ${({ isShow }) => (isShow ? '-1em' : '-2em')};
  }
`;
