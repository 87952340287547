import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IContainer {
  show?: boolean;
  sending: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  bottom: 50px;
  right: 0;
  color: #0c5c75;
  padding: 15px;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  visibility: hidden;
  background: white;
  margin: 20px;
  margin-right: -200px;
  box-shadow: 0 0 10px 0px #157e8e7a;
  flex-direction: column;
  z-index: 998;
  opacity: 0;
  transition: 0.6s;

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
      margin-right: 20px;
    `}

  button.closeMessageButton {
    background: transparent;
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      color: #0c5c75;
      font-size: 1.6em;
    }
  }

  h2 {
    font-size: 1em;
    margin-right: 25px;
  }

  & form {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    & p {
      margin-top: 20px;
      margin-bottom: 10px;

      font-size: 0.8em;
    }
    & button {
      background-color: #1b9eb2;
      padding: 10px;
      border-radius: 5px;
      margin-top: 10px;
      font-size: 0.8em;
      &:hover {
        background: ${shade(0.3, '#1b9eb2')};
      }

      ${props =>
        props.sending &&
        css`
          background: ${shade(0.3, '#1b9eb2')};
          cursor: not-allowed;
          color: #1b9eb2;
        `}
    }
  }
`;
