import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { MdEmail, MdDeviceUnknown } from 'react-icons/md';
import { TiDownload } from 'react-icons/ti';
import { RiUserSearchFill } from 'react-icons/ri';
import { MdSensors } from 'react-icons/md';

import { AxiosError } from 'axios';
import { IMessage, useAuth } from '../../../../hooks/auth';
import { useSelectInfo } from '../../../../hooks/selectInfo';
import { UserRolesEnum } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';

import PageTitle from '../../../../components/PageTitle';
import DownloadData from '../../components/DownloadCSV';
import LineGraph from './LinearGraph';
import Message from './Message';

import * as S from './styles';
import { Advice } from '../../../../components/Advice';
import { IDevice, ISensor } from '../../../../interfaces';
import { SelectDevice } from './styles';
import DownloadCSV from '../../components/DownloadCSV';

const Dashboard: React.FC = () => {
  const { selectedSensorId, selectSensor, selectedUser } = useSelectInfo();
  const { user } = useAuth();
  const { addToast } = useToast();

  const [selectedDeviceName, setSelectedDeviceName] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showDownloadData, setShowDownloadData] = useState(false);

  const [sensorList, setSensorList] = useState<ISensor[]>([] as ISensor[]);
  const [messages, setMessages] = useState<IMessage[]>([]);

  const selectedSensor = useMemo(() => sensorList.find(sensor => sensor.id === selectedSensorId), [selectedSensorId]);

  useEffect(() => {
    if (!selectedUser?.messages) return;

    if (selectedUser.messages?.length === 0) return;

    const notReadedMessages = selectedUser.messages?.filter(message => message.not_readed);

    setMessages([...notReadedMessages]);
  }, [selectedUser]);

  const searchDeviceHandle = useCallback(async () => {
    if (!selectedDeviceName) return;

    try {
      const { data } = await api.get<IDevice[]>('devices', { params: { name: selectedDeviceName } });

      setSensorList(data[0]?.sensors || []);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar dispositivo',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    }
  }, [addToast, selectedDeviceName]);

  useEffect(() => {
    if (selectedDeviceName) {
      searchDeviceHandle();
    }
  }, [selectedDeviceName, searchDeviceHandle]);

  useEffect(() => {
    setSensorList([...[]]);
    if (selectedUser.devices && selectedUser.devices.length === 1) setSelectedDeviceName(selectedUser.devices[0].name);
    selectSensor('');
  }, [selectedUser]);

  const selectDevice = (deviceId: string) => {
    setSelectedDeviceName(deviceId);
  };

  const devicesList = useMemo(() => {
    return (
      <ul className="devicesList">
        <div>
          <div>
            <h3>Dispositivo</h3>
            <SelectDevice
              onChange={e => {
                selectSensor('');
                selectDevice(e.target.value);
              }}
              value={selectedDeviceName}
            >
              <option value={undefined}>Selecione um dispositivo</option>
              {selectedUser.devices?.map(device => (
                <option key={device.name} value={device.name}>
                  {device.name}
                </option>
              ))}
            </SelectDevice>
          </div>
          <S.Devbar>
            {user.role === UserRolesEnum.ADMIN || (
              <button type="button" className="sendMessageButton" onClick={() => setShowMessage(true)}>
                Mensagem
              </button>
            )}

            {!!messages?.length && user.role === UserRolesEnum.ADMIN && (
              <button type="button" className="viewMessageButton" onClick={() => setShowMessage(true)}>
                <MdEmail />
              </button>
            )}

            <div>
              {selectedSensorId && (
                <button type="button" className="sendMessageButton" onClick={() => setShowDownloadData(true)}>
                  <TiDownload size="1.5em" />
                </button>
              )}
            </div>
            <S.Sensorlist>
              {sensorList.map(({ id, name }) => (
                <button
                  type="button"
                  key={id}
                  onClick={() => {
                    selectSensor(id); // Seleciona o sensor
                  }}
                  className={selectedSensorId === id ? 'active' : ''}
                >
                  {name}
                </button>
              ))}
            </S.Sensorlist>
          </S.Devbar>
        </div>
      </ul>
    );
  }, [selectSensor, selectedDeviceName, selectedSensorId, selectedUser, sensorList, user.role, messages]);

  const nonExistentDeviceMessage = useMemo(() => {
    if (user?.role !== UserRolesEnum.ADMIN) return 'Nenhum dispositivo foi atribuído a você';

    if (selectedUser?.id) return 'Atribua um dispositivo para esse usuário';

    return 'Selecione um usuário';
  }, [user, selectedUser]);

  const nonExistentDeviceIcon = useMemo(() => {
    if (user?.role !== UserRolesEnum.ADMIN) return MdDeviceUnknown;

    if (selectedUser?.id) return MdDeviceUnknown;

    return RiUserSearchFill;
  }, [user, selectedUser]);

  return (
    <S.DashboardWrapper>
      <PageTitle sub={`Dashboard ${user?.role === UserRolesEnum.ADMIN && selectedUser?.name ? `de ${selectedUser?.name}` : ''}`} title="Controle" />
      {/* Removed the old charts and restructured the dashboard */}
      <S.Container>
        <Grid container style={{ overflow: 'visible' }} spacing={3}>
          {selectedUser?.devices?.length > 0 ? (
            <>
              <Grid item xs={12}>
                {devicesList}
              </Grid>
              {/* Renderiza o gráfico apenas se um sensor estiver selecionado */}
              {selectedDeviceName ? (
                selectedSensorId ? (
                  <S.Graph>
                    <LineGraph selectedSensor={selectedSensor} />
                  </S.Graph>
                ) : (
                  <Grid item xs={12}>
                    <Advice text={'Selecione um Sensor'} icon={MdSensors} />
                  </Grid>
                )
              ) : (
                <Grid item xs={12}>
                  <Advice text={'Selecione um dispositivo'} icon={MdSensors} />
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12}>
              <Advice text={nonExistentDeviceMessage} icon={nonExistentDeviceIcon} />
            </Grid>
          )}
        </Grid>

        <Message messages={messages} setMessages={setMessages} show={showMessage} closeFunction={setShowMessage} />
        <DownloadCSV show={showDownloadData} closeFunction={setShowDownloadData} sensorId={selectedSensorId} csvUrl="sensorCSVData" />
      </S.Container>
    </S.DashboardWrapper>
  );
};

export default Dashboard;
