import React from 'react';
import { IconBaseProps } from 'react-icons';
import { Container } from './styles';

type AdviceProps = {
  text: string;
  icon?: React.ComponentType<IconBaseProps>;
};

export const Advice: React.FC<AdviceProps> = ({ text, icon: Icon }) => {
  return (
    <Container>
      {Icon && <Icon size={130} color="#0C5C75" />}
      <h2>{text}</h2>
    </Container>
  );
};
