import React, { ButtonHTMLAttributes, useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { IoMdCloseCircle } from 'react-icons/io';
import * as Yup from 'yup';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import { Container } from './styles';

import { useToast } from '../../../../hooks/toast';

import api from '../../../../services/api';
import { format } from 'date-fns';

interface InputProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  show?: boolean;
  closeFunction: (arg0: boolean) => void;
  sensorId: string;
  csvUrl: string;
}

const DownloadCSV: React.FC<InputProps> = ({ show, closeFunction, sensorId, csvUrl }) => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [CSVId, setCSVId] = useState('');

  const submitMessage = useCallback(
    async formData => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          initialDate: Yup.string().required('Data inicial obrigatoria'),
          finalDate: Yup.string().required('Data final obrigatoria'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { finalDate, initialDate } = formData;
        const dateFinal = new Date(finalDate);
        const formatfinaldate = format(dateFinal, 'y-MM-dd HH:mm:ss.SSS');
        const dateinitial = new Date(initialDate);
        const formatinitialdate = format(dateinitial, 'y-MM-dd HH:mm:ss.SSS');
        const { data } = await api.post(
          csvUrl,
          {},
          {
            params: { sensor_id: sensorId, final_date: formatfinaldate, initial_date: formatinitialdate },
          },
        );

        setCSVId(data);
      } catch (error) {
        return addToast({
          type: 'error',
          title: 'Erro ao criar CSV',
          description: error.response ? `${error.response.data.message}` : `${error}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, sensorId],
  );

  const closeHandler = useCallback(async () => {
    if (!CSVId) {
      return closeFunction(false);
    }

    setCSVId('');

    return closeFunction(false);
  }, [closeFunction, CSVId, addToast]);

  const opencsv = useCallback(async () => {
    if (!process.env.REACT_APP_API_URL) throw new Error('REACT_APP_API_URL environment variable not defined.');

    const baseURL = process.env.REACT_APP_API_URL;
    const link = `${baseURL}/csvFiles/${CSVId}.csv`;

    window.open(link);

    // Adiciona um atraso antes de deletar o arquivo CSV
    setTimeout(async () => {
      await api.delete(csvUrl, {
        params: { file_name: `${CSVId}.csv` },
      });
    }, 1000); // 1 segundo de delay

    formRef.current?.reset();

    addToast({
      title: 'Sucesso',
      description: 'CSV exportado com sucesso!',
      type: 'success',
    });
  }, [CSVId]);

  return (
    <Container show={show} sending={loading}>
      <button className="closeMessageButton" type="button" onClick={closeHandler}>
        <IoMdCloseCircle />
      </button>
      <h2>Selecione um intervalo de data</h2>
      <Form onSubmit={submitMessage} id="form" ref={formRef}>
        <p>Data Inicial:</p>
        <Input name="initialDate" type="datetime-local" />

        <p>Data final:</p>
        <Input name="finalDate" type="datetime-local" />

        {!CSVId ? <Button type="submit">{loading ? 'Criando...' : 'Criar CSV'}</Button> : ''}
        {CSVId && (
          <Button type="button" onClick={opencsv}>
            Download do arquivo
          </Button>
        )}
      </Form>
    </Container>
  );
};

export default DownloadCSV;
