import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface containerInterface {
  error: boolean;
}

export const Container = styled.div<containerInterface>`
  & input {
    font-size: 1rem;
    font-weight: 300;
    color: #808080;
    padding: 0 5px;
    height: 25px;
    border: none;
    border-radius: 5px;
    background: #e3eff4;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);

    @media (max-width: 500px) {
      height: 1.25rem;
      font-size: 0.875rem;
    }

    &::-webkit-calendar-picker-indicator {
      margin-left: -10px;
      opacity: 0.5;
    }
  }

  ${props =>
    props.error &&
    css`
      background-color: transparent;
      border-color: #c53030;
    `}
`;

export const Error = styled(Tooltip)`
  display: flex;
  position: absolute;
  background-color: transparent;
  height: 40px;
  align-items: center;
  margin-left: -40px;
  max-width: 40px;
  padding-left: 10px;

  svg {
    margin: 0;
  }

  span {
    border: none;
    background: #c53030;
    color: #fff;
    margin-left: 160px;

    &::before {
      border-color: none;
    }
  }
`;
