import React, { useCallback, useEffect, useState } from 'react';
import { Popover } from '@material-ui/core';

import { Container } from './styles';
import Notification from './Notification';
import { IAlert, IDevice, ISensor } from '../../../../interfaces';
import { useToast } from '../../../../hooks/toast';
import { useAuth } from '../../../../hooks/auth';
import socket from '../../../../services/io';
import api from '../../../../services/api';

interface INotificationsPopUpProps {
  anchor: Element | null;
  handleClose: () => void;
  setNotification: (num: number) => void;
  isOpen: boolean;
}

interface INotificationAlert {
  id: string;
  device: IDevice;
  sensor?: ISensor;
  read?: boolean;
  timestamp: Date;
  type: 'sensor' | 'battery';
}

interface IBatteryAlert {
  id: string;
  device: IDevice;
  device_id: string;
  message: string;
  read: boolean;
  timestamp: Date;
}

const NotificationsPopUp: React.FC<INotificationsPopUpProps> = ({ anchor, handleClose, isOpen, setNotification }) => {
  const [alerts, setAlerts] = useState<INotificationAlert[]>([]);
  const { addToast } = useToast();
  const { user } = useAuth();

  const getUserAlerts = useCallback(async () => {
    const { data: receivedSensorAlerts } = await api.get<IAlert[]>('/alerts/user', {
      params: {
        user_id: user.id,
        alert_number: 7,
      },
    });

    const sensorAlerts: INotificationAlert[] = receivedSensorAlerts.map(item => ({
      id: item.id,
      device: item.sensor.device,
      sensor: item.sensor,
      timestamp: new Date(item.timestamp),
      read: item.read,
      type: 'sensor',
    }));

    const { data: receivedBatteryAlerts } = await api.get<IBatteryAlert[]>('/battery/alerts/user', {
      params: {
        user_id: user.id,
        alert_number: 7,
      },
    });
    const batteryAlerts: INotificationAlert[] = receivedBatteryAlerts.map(item => ({
      id: item.id,
      device: item.device,
      timestamp: new Date(item.timestamp),
      read: item.read,
      type: 'battery',
    }));

    const allAlerts: INotificationAlert[] = [...sensorAlerts, ...batteryAlerts].sort((a, b) => {
      return b.timestamp.getTime() - a.timestamp.getTime();
    });

    const unreadAlerts = allAlerts.filter(alert => !alert.read);

    let selectedAlerts = unreadAlerts.slice(0, 7);

    if (selectedAlerts.length < 7) {
      const additionalAlerts = allAlerts.filter(alert => !selectedAlerts.includes(alert));
      selectedAlerts = [...selectedAlerts, ...additionalAlerts.slice(0, 7 - selectedAlerts.length)];
    }

    setAlerts(selectedAlerts);
  }, [user.id]);

  const onSocketMessage = (data: string) => {
    const alert = JSON.parse(data);

    getUserAlerts();

    addToast({
      type: 'info',
      title: 'Alerta',
      description: alert.message,
    });
  };

  useEffect(() => {
    socket.on(user.id, onSocketMessage);

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    getUserAlerts();
  }, []);

  const refreshNotifications = async () => {
    const filterOnlyNotReadAlerts = alerts.filter(alert => !alert.read);

    setNotification(filterOnlyNotReadAlerts.length);
  };

  useEffect(() => {
    refreshNotifications();
  }, [alerts, setNotification]);

  return (
    <Popover
      open={isOpen}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container>
        {alerts.length > 0 ? (
          alerts.map(alert => (
            <Notification
              onReadNotification={getUserAlerts}
              key={alert.id}
              alert_id={alert.id}
              device_name={alert.device.name}
              device_id={alert.device.id}
              sensor_name={alert.sensor?.name}
              sensor_id={alert.sensor?.id}
              role={user.role}
              read={alert.read}
              type={alert.type}
            />
          ))
        ) : (
          <strong> Nenhum alerta encontrado.</strong>
        )}
      </Container>
    </Popover>
  );
};

export default NotificationsPopUp;
