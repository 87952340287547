import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Devices from '../NewDevice/index';
import EditDevices from '../EditDevice';
import EditSensor from '../Sensor';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/app/device" exact component={Devices} />
      <Route path="/app/device/:id" exact component={EditDevices} />
      <Route path="/app/device/sensor/:id" exact component={EditSensor} />
    </Switch>
  );
};

export default Routes;
