import React from 'react';

import { Container } from './styles';

interface PageTitleProps {
  title: string;
  sub: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ sub, title, children }) => {
  return (
    <Container>
      <div className="title">
        <div />
        <p>
          {title}
          <br />
          <strong>{sub}</strong>
        </p>
      </div>
      {children}
    </Container>
  );
};

export default PageTitle;
