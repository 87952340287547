import { FaSpinner } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 #eaeaea;

  @media (max-width: 1025px) {
    height: 600px;
  }

  @media (max-width: 768px) {
    height: 500px;
  }

  @media (max-width: 500px) {
    height: 375px;
  }

  & > ul {
    display: flex;

    & button,
    li {
      font-size: 0.7em;
      background: transparent;
      color: #a7d1e1;
      & strong {
        font-weight: 600;
      }
      & + button {
        margin-left: 15px;
      }
      &:hover {
        color: #0c5c75;
      }
    }
    & button.active,
    li.active {
      color: #0c5c75;
    }
  }
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoadingSpinner = styled(FaSpinner)`
  color: #1b9eb2;
  position: absolute;
  animation: ${spin} 1s linear infinite;

  top: 10px;

  @media (min-width: 768px) {
    left: 10px;
  }

  @media (max-width: 768px) {
    right: 5px;
  }
`;

export const LiveButton = styled.button<{ live: boolean }>`
  transition: all 0.3s;
  opacity: ${({ live }) => (live ? 1 : 0.7)};
  border-radius: 10px;
  background-color: #e3eff4;

  display: flex;
  align-items: center;

  font-size: 10pt;

  padding: 0.2rem 0.5rem;

  & > .live-icon {
    transition: all 0.3s;
    margin-right: 0.2rem;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: ${({ live }) => (live ? 'red' : 'gray')};
  }
`;

export const FiltersPopUp = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 30px;
  min-width: 350px;

  border-radius: 10px;
  -webkit-box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);

  strong {
    color: #0c5c75;
    font-size: 1rem;
    font-weight: bold;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
      color: white;
      width: 100%;
    }

    .date-input {
      div {
        border: 2px solid #0c5c75;
        padding: 10px;
        border-radius: 10px;

        input {
          width: 100%;
          box-shadow: none;
          background: inherit;
        }
      }
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  .mobile-row {
    display: flex;
    justify-content: space-between;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .mobile-filters-button {
    width: 100px;
    color: white;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const GrafContainer = styled.div`
  display: flex;
  height: 90%;
  width: 95%;
  padding: 10px;

  margin-top: 0.5rem;

  @media (max-width: 768px) {
    order: 1;
    width: 100%;
  }
  @media (max-width: 500px) {
    order: 1;
    width: 100%;
    padding: 3rem 0 1rem 0;
  }
`;

export const Button = styled.button`
  background-color: #1b9eb2;
  margin-right: 16px;
  margin-left: 16px;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #157e8e;
  }

  @media (max-width: 768px) {
    margin-right: 25px;
    margin-left: 0;
  }
`;

export const FilterContainer = styled.div`
  position: relative;
  flex: 1;
  height: 5%;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }

  & > div.filterLegends {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    & ul {
      flex-direction: column;
      display: flex;

      & > li {
        margin: 3px 0;
        font-size: 0.8em;
        font-weight: 500;
        color: #0c5c75;
        &::before {
          content: '';
          padding: 0 1px;
          margin-right: 10px;
        }
      }
    }

    & > section {
      display: flex;
      align-items: end;
      justify-content: flex-end;
      gap: 1rem;

      .max-input {
        width: 90px;
        height: 25px;
        border: 0;
        background: #e3eff4;
        box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
        padding: 0 5px;

        @media (max-width: 768px) {
          gap: 0.5rem;
          width: 100%;
        }
      }

      @media (max-width: 768px) {
        gap: 0.5rem;
      }
    }

    @media (max-width: 768px) {
      order: 0;
      width: 100%;
      padding-top: 0;

      & > div.filterLegends {
        justify-content: space-between;
        & ul {
          display: flex;
          flex-direction: row;
          li {
            margin-right: 10px;
          }
        }
        & > form {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding-bottom: 10px;
          flex: 1;
        }
      }
    }
  }
`;
