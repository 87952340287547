import styled from 'styled-components';
import { shade } from 'polished';
import { Grid } from '@material-ui/core';
import { Container as LinearContainer } from './LinearGraph/styles';
import Select from '../../../../components/Select';

export const DashboardWrapper = styled.div`
  height: 100vh;
  overflow: auto;

  @media (min-width: 500px) {
    overflow: hidden;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  padding: 15px;
  max-width: 100%;
  padding-right: 0.5rem;

  @media (min-width: 500px) {
    overflow: auto;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #d7d7d7;
  }
  ::-webkit-scrollbar-thumb {
    background: #a9a9a9;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  & div.doubt {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 50px;
    & img {
      width: 150px;
    }
    & h2 {
      color: #0c5c75;
      font-size: 2rem;
      font-weight: bold;
    }

    @media (max-width: 768px) {
      & img {
        width: 100px;
      }
      & h2 {
        font-size: 1em;
      }
    }
  }

  selectDev {
    width: 100%;
    height: 70px;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
  }

  & ul.devicesList {
    width: 100%;
    & > div {
      display: flex;
      flex-direction: column;
      width: auto;
      padding: 10px 30px;

      @media (max-width: 500px) {
        padding: 0;
      }

      justify-content: center;
      & h3 {
        color: #0c5c75;
        font-size: 23px;
      }
      & button {
        background: transparent;
        display: inline-block;
        color: #1b9eb2;
        padding: 0px 10px;
        font-size: 1.125rem;
        border-bottom: 2px solid #fff;

        &:hover {
          color: #0c5c75;
        }
      }
      & button.active {
        font-weight: 600;
        color: #0c5c75;
      }

      & button.sendMessageButton {
        background: #1b9eb2;
        color: white;
        border-radius: 5px;
        padding: 10px 15px;
        font-size: 0.9em;
        margin-right: 20px;
        font-weight: 400;
        &:hover {
          background: ${shade(0.2, '#1b9eb2')};
        }
      }
      & button.viewMessageButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5em;
        height: 2.5em;
        border: 3px solid #1b9eb2;
        border-radius: 50%;
        background: transparent;
        color: #1b9eb2;
        margin-right: 20px;
        font-weight: 400;
        transition: 0.6s;
        & svg {
          font-size: 1.5em;
          &:hover {
            color: ${shade(0.2, '#1b9eb2')};
          }
        }
        &:hover {
          border-color: ${shade(0.2, '#1b9eb2')};
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 80px;
  }
`;

export const SkeletonContainer = styled(LinearContainer)`
  height: 100%;
  overflow: hidden;
  & span {
    background: #e3eff4;
  }

  & div.skeletonContainer {
    display: flex;
    flex: 1;
  }
`;

export const SelectDevice = styled.select`
  font-size: 1rem;
  font-weight: 300;
  margin-right: 50px;
  margin-top: 0.5rem;
  color: #808080;
  padding: 0.25rem 2rem 0.25rem 0.5rem;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #e3eff4;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;
export const SearchDeviceForm = styled.div`
  width: 600px;
  height: 100%;
  overflow: hidden;

  & form.search {
    width: 250px;
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
    margin-top: 0;
    margin-bottom: 70px;
  }
`;

export const Devbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 70px;
  margin-top: 1rem;

  overflow-x: auto;

  @media (max-width: 500px) {
    margin: 2rem 0 1.5rem 0;
  }
`;

export const Sensorlist = styled.div`
  max-width: 100%;

  white-space: nowrap;

  overflow-x: auto;

  padding-bottom: 0.25rem;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #ddd;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const Dropdevice = styled(Select)`
  width: 398px;
`;

export const Graph = styled(Grid)`
  width: 100%;
  height: 100%;
`;
