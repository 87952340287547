import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IContainer {
  isLoading?: boolean;
}

export const Container = styled.button<IContainer>`
  background-color: #0c5c75;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  border-radius: 10px;
  font-weight: 500;
  &:hover {
    background: ${shade(0.2, '#0c5c75')};
  }

  ${props =>
    props.isLoading &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}
`;
