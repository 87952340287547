import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FaUserXmark } from 'react-icons/fa6';
import Grid from '@material-ui/core/Grid';
import { RiUserAddLine, RiArrowLeftSLine } from 'react-icons/ri';
import { FaEdit } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import { FiAlertTriangle } from 'react-icons/fi';

import { AxiosError } from 'axios';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationsErrors';
import api from '../../../../services/api';

import * as S from './styles';
import { IUser, UserRolesEnum, useAuth } from '../../../../hooks/auth';
import Upload from '../../../../components/Upload';
import userImageDefault from '../../../../assets/logo_tech_Prancheta.png';
import { Advice } from '../../../../components/Advice';
import Slider from '../../../../components/Slider';

interface ISearchUserParams {
  searchUser?: string;
}

interface INewUser {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  default_password: boolean;
}

const AdminUser: React.FC = () => {
  const personalFormRef = useRef<FormHandles>(null);
  const personalUpdateFormRef = useRef<FormHandles>(null);

  const { user } = useAuth();
  const { addToast } = useToast();

  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState<IUser[]>([] as IUser[]);
  const [register, setRegister] = useState(false);
  const [updateUser, setUpdateUser] = useState<IUser>();
  const [file, setFile] = useState<File>();
  const [deleteAvatar, setDeleteAvatar] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [useDefaultPassword, setUseDefaultPassword] = useState(false);

  const userRoleLabels = {
    [UserRolesEnum.ADMIN]: 'Administrador',
    [UserRolesEnum.PROJECT]: 'Projeto',
    [UserRolesEnum.OPERATOR]: 'Operador',
  };

  const searchUsersList = useCallback(
    async ({ searchUser }: ISearchUserParams) => {
      try {
        const { data } = await api.get('profiles', { params: { searchUser } });

        return setUserList(data);
      } catch (error) {
        return addToast({
          type: 'error',
          title: 'Erro ao buscar usuários',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      }
    },
    [addToast],
  );

  useEffect(() => {
    searchUsersList({});
  }, [searchUsersList]);

  const personalFormHandler = useCallback(
    async (formData: INewUser) => {
      setLoader(true);

      try {
        personalFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().email().required(),
          name: Yup.string().required(),
          password: Yup.string(),
          confirm_password: Yup.string().equals([formData.password]),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { name, email, password } = formData;

        const dataToSend = {
          name,
          email,
          password: formData.default_password ? 'SWTech' : password,
          password_confirmation: formData.default_password ? 'SWTech' : password,
        };

        const data = new FormData();

        if (file) {
          data.append('file', file, file.name);
        }

        const user = await api.post('/users', dataToSend);

        await api.patch('profiles/avatar', data, {
          params: {
            userId: user.data.id,
          },
        });

        personalFormRef.current?.reset();

        setRegister(false);
        searchUsersList({});
        setFile(undefined);

        return addToast({
          type: 'success',
          title: 'Usuário criado!',
          description: 'Usuário criado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return personalFormRef.current?.setErrors(errors);
        }

        return addToast({
          type: 'error',
          title: 'Erro ao criar novo usuário',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoader(false);
      }
    },
    [addToast, file, searchUsersList],
  );

  const personalUpdateFormHandler = useCallback(
    async formData => {
      setLoader(true);

      try {
        personalFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string().email(),
          name: Yup.string(),
          password: Yup.string(),
          oldPassword: Yup.string(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { name, email, password, oldPassword } = formData;

        const dataToSend = {
          name: name || updateUser?.name,
          email: email || updateUser?.email,
          password,
          oldPassword,
          password_confirmation: password,
          user_id: updateUser?.id,
        };

        password === '' && delete dataToSend.password;
        password === '' && delete dataToSend.password_confirmation;
        oldPassword === '' && delete dataToSend.oldPassword;

        const data = new FormData();

        if (file) {
          data.append('file', file, file.name);
        }

        (deleteAvatar || file) &&
          (await api.patch('profiles/avatar', data, {
            params: {
              userId: updateUser?.id,
            },
          }));

        await api.put('/profiles', dataToSend);

        personalFormRef.current?.reset();

        setRegister(false);
        setUpdateUser(undefined);
        setFile(undefined);
        searchUsersList({});

        return addToast({
          type: 'success',
          title: 'Usuário atualizado!',
          description: 'Usuário atualizado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return personalFormRef.current?.setErrors(errors);
        }

        return addToast({
          type: 'error',
          title: 'Erro ao atualizar usuário',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoader(false);
      }
    },
    [addToast, updateUser, searchUsersList, file, deleteAvatar],
  );

  const personalDeleteHandler = useCallback(async () => {
    setLoader(true);

    try {
      const data = new FormData();

      await api.patch('profiles/avatar', data, {
        params: {
          userId: updateUser?.id,
        },
      });

      await api.delete('/profiles', {
        data: {
          id: updateUser?.id,
        },
      });

      setUpdateUser(undefined);
      setFile(undefined);
      setDeleteUser(false);
      searchUsersList({});

      return addToast({
        type: 'success',
        title: 'Usuário deletado!',
        description: 'Usuário deletado com sucesso!',
      });
    } catch (error) {
      return addToast({
        type: 'error',
        title: 'Erro ao deletar usuário',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    } finally {
      setLoader(false);
      setDeleteUser(false);
    }
  }, [addToast, updateUser, searchUsersList]);

  const personalForm = useMemo(
    () => (
      <>
        <Form ref={personalFormRef} onSubmit={personalFormHandler}>
          {user.role === UserRolesEnum.ADMIN && (
            <div className="upload">
              {!file ? (
                <Upload uploadData={data => setFile(data[0])} />
              ) : (
                <>
                  <HiOutlineTrash
                    size={20}
                    onClick={() => {
                      setFile(undefined);
                    }}
                  />
                  <img src={file ? URL.createObjectURL(file) : ''} alt="logo" />
                </>
              )}
            </div>
          )}
          <div className="form">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className={loader ? 'disabled' : 'input'}>
                <p>Nome</p>
                <Input name="name" type="text" />
              </Grid>
              <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                <p>Email</p>
                <Input name="email" type="email" />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {!useDefaultPassword && (
                <>
                  <Grid item xs={12} sm={12} className={loader ? 'disabled' : 'input'}>
                    <p>Senha</p>
                    <Input name="password" type="password" />
                  </Grid>
                  <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                    <p>Confirme a senha</p>
                    <Input name="confirm_password" type="password" />
                  </Grid>
                </>
              )}
              <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                <p>Usar senha padrão</p>
                <Slider
                  name="default_password"
                  defaultChecked={useDefaultPassword}
                  onChange={({ target }) => {
                    console.log('target', target.checked);
                    setUseDefaultPassword(target.checked);
                  }}
                />
              </Grid>
            </Grid>

            <Grid>
              {loader ? (
                <Button>Cadastrando ...</Button>
              ) : (
                <Button>
                  <RiUserAddLine size={20} />
                  Cadastrar
                </Button>
              )}
            </Grid>
          </div>
        </Form>
      </>
    ),
    [loader, personalFormHandler, file, useDefaultPassword],
  );

  const personalUpdateForm = useMemo(
    () => (
      <>
        <Form ref={personalUpdateFormRef} onSubmit={formData => personalUpdateFormHandler({ ...formData, id: updateUser?.id })}>
          {updateUser?.role !== UserRolesEnum.OPERATOR && (
            <div className="upload">
              {!file && !updateUser?.avatar_url ? (
                <Upload uploadData={data => setFile(data[0])} />
              ) : (
                <>
                  <HiOutlineTrash
                    size={20}
                    onClick={() => {
                      setFile(undefined);
                      setDeleteAvatar(true);
                      setUpdateUser(prev => {
                        if (!prev) return prev;
                        return {
                          ...prev,
                          avatar_url: '',
                        };
                      });
                    }}
                  />
                  <img src={(file ? URL.createObjectURL(file) : updateUser?.avatar_url) || ''} alt="logo" />
                </>
              )}
            </div>
          )}
          <div className="form">
            <Grid container spacing={2} className="inputs">
              <Grid item xs={12} sm={12} className={loader ? 'disabled' : 'input'}>
                <p>Nome</p>
                <Input name="name" type="text" defaultValue={updateUser?.name} />
              </Grid>
              <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                <p>Email</p>
                <Input name="email" type="email" defaultValue={updateUser?.email} />
              </Grid>
              <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                <p>Senha Antiga</p>
                <Input name="oldPassword" type="password" />
              </Grid>
              <Grid item xs={12} className={loader ? 'disabled' : 'input'}>
                <p>Senha Nova</p>
                <Input name="password" type="password" />
              </Grid>
            </Grid>

            <Grid>
              {loader ? (
                <Button>Atualizando ...</Button>
              ) : (
                <Button>
                  <RiUserAddLine size={20} />
                  Atualizar
                </Button>
              )}
            </Grid>
          </div>
          <HiOutlineTrash size={15} className="trash" onClick={() => setDeleteUser(true)} />
        </Form>
      </>
    ),
    [loader, personalUpdateFormHandler, updateUser, file],
  );

  return (
    <>
      <S.Container>
        {(register || updateUser) && (
          <div className="close">
            <button
              type="button"
              onClick={() => {
                setRegister(false);
                setUpdateUser(undefined);
                setFile(undefined);
              }}
            >
              <RiArrowLeftSLine size={20} />
              <p>Voltar</p>
            </button>
          </div>
        )}

        {register && <S.AdminUpdateFormContainer>{personalForm}</S.AdminUpdateFormContainer>}

        {updateUser && <S.AdminUpdateFormContainer>{personalUpdateForm}</S.AdminUpdateFormContainer>}

        {!register && !updateUser && (
          <>
            <S.Register>
              <button
                className="register"
                type="button"
                onClick={() => {
                  setRegister(true);
                }}
              >
                <RiUserAddLine size={20} />
                Cadastrar
              </button>
            </S.Register>
            <S.ListUser>
              {userList.length !== 0 ? (
                <ul>
                  {userList.map(user => (
                    <li key={user.id}>
                      <div className="userItem">
                        {user.role !== UserRolesEnum.OPERATOR && (
                          <>
                            {user.avatar_url ? <img src={user.avatar_url} alt="logo" /> : <img className="default" src={userImageDefault} alt="logo padrão" />}
                          </>
                        )}
                        <p className="name">{user.name}</p>
                        <p className="email">{user.email}</p>
                        <p className="role">{userRoleLabels[user.role]}</p>
                        <FaEdit
                          onClick={() => {
                            setUpdateUser(user);
                          }}
                        />
                      </div>
                      <ul className="operatorList">
                        {user.operations &&
                          user.operations.map(operator => (
                            <li key={operator.id} className="userItem">
                              <p className="name">{operator.name}</p>
                              <p className="email">{operator.email}</p>
                              <p className="role">{userRoleLabels[operator.role]}</p>
                              <FaEdit
                                onClick={() => {
                                  setUpdateUser(operator);
                                }}
                              />
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              ) : (
                <Grid item xs={12}>
                  <Advice text="Nenhum usuário cadastrado" icon={FaUserXmark} />
                </Grid>
              )}
            </S.ListUser>
          </>
        )}
        {deleteUser && (
          <div className="delete">
            <div>
              <FiAlertTriangle size={30} />
              <p>Deseja deletar o usuário?</p>
              <div className="buttons">
                <Button className="c" onClick={() => setDeleteUser(false)}>
                  Cancelar
                </Button>
                <Button className="d" onClick={personalDeleteHandler}>
                  Deletar
                </Button>
              </div>
            </div>
          </div>
        )}
      </S.Container>
    </>
  );
};

export default AdminUser;
