import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';
import { UpdateFormContainer } from '../../User/styles';
import { Content } from '../../../layout/AdminToolContent/styles';

const appearZ = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-100px)

  }
  to {
    opacity: 1;
    transform: translateZ(0px)

  }
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px)

  }
  to {
    opacity: 1;
    transform: translateX(0px)

  }
`;

export const Container = styled.div`
  overflow: auto;
  @media (max-width: 768px) {
    height: 800px;
    padding-bottom: 2rem;
  }
  @media (min-width: 1385px) {
    display: flex;
  }
`;

export const AdminUpdateFormContainer = styled(UpdateFormContainer)`
  box-shadow: none;
  animation: ${appear} 0.6s;
  height: fit-content;
  & form {
    padding: 10px 30px;

    & div.mac {
      width: 60%;
    }

    & div.disabled {
      div {
        background: #ececec;
        border: 2px solid #ececec;
        cursor: not-allowed;
        input {
          color: #b9b9b9;
          cursor: not-allowed;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    box-shadow: none;
    & form {
      & button.searchUserforDevice {
        p {
          font-size: 0.8em;
        }
      }
    }
  }

  @media (max-width: 768px) {
    order: 0;
    padding-bottom: 25px;
    & form {
      & > div {
        & > div {
          & > div {
            padding: 5px;
            svg {
              font-size: 1.5em;
            }
            & input {
            }
          }
        }
      }
      & button.submitNewDeviceButton {
        padding: 8px;
      }

      & button.searchUserforDevice {
        p {
          display: none;
        }
        svg {
          margin: 0;
        }
      }
    }
  }
`;

export const DeletePopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearZ} 0.7s;
  z-index: 9999;

  & div {
    background-color: #ffffff;
    opacity: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 300px;
    border-radius: 20px;
    gap: 50px;

    & svg {
      color: #ff0000;
      scale: 3;
    }

    & p {
      font-size: 18px;
      font-weight: 800;
      color: #000000;
    }

    & div.buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 50px;
      width: 100%;
      height: auto;

      & button.delete {
        width: 35%;
        background-color: #ff0000;
      }
      & button.cancel {
        width: 35%;
        background-color: transparent;
        border: 2px solid #ff0000;
        color: #ff0000;
      }
    }
    @media (max-width: 768px) {
      width: 350px;
      height: 400px;
    }
  }
`;

export const SearchDeviceForm = styled(Content)`
  width: 600px;
  animation: ${appear} 0.6s;

  & form.search {
    width: 250px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 70px;
  }
`;

export const SearchDevice = styled(Content)`
  width: 700px;
  animation: ${appear} 0.6s;

  & > div.results {
    > h3 {
      font-size: 1rem;
    }

    h3.result {
      color: #afafaf;
      margin-top: 10px;
    }

    & ul {
      width: 100%;
      overflow: auto;
      max-height: 450px;
      & button {
        background-color: #f6f6f6;
        border-radius: 10px;
        width: 95%;
        height: 62px;
        padding: 0 20px;
        & p {
          font-weight: 400;
          width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        & span {
          & svg {
            font-size: 2em;
            color: #000000;
            &:hover {
              color: ${shade(0.3, '#666666')};
              cursor: pointer;
            }
          }
        }
        & span.confirm {
          & svg {
            color: #fa0000;
            &:hover {
              color: #fa0000;
              cursor: pointer;
            }
          }
        }

        &:hover {
          font-weight: 300;
          cursor: default;
        }
      }

      ::-webkit-scrollbar {
        width: 15px;
      }
      ::-webkit-scrollbar-track {
        background: #ececec;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${shade(0.3, '#ececec')};
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
    margin-top: 0;
    margin-bottom: 70px;
  }
`;

export const CheckContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;

  p {
    text-align: left;
    width: fit-content;
  }
`;

export const NewSensorPopup = styled.div`
  width: 100%;

  button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & div.close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormFieldsContainer = styled.div<{ height: number }>`
  display: flex;
  height: ${({ height }) => height}rem;
  width: 100%;
  transition: 1s;
  overflow: hidden;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;

  & div.slider-container {
    display: flex;
    justify-content: space-between;
    p {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: 10px;
    }
  }
`;
