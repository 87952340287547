import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface UploadProps {
  isDragActive: boolean;
  isDragReject: boolean;
  refKey?: string;
  [key: string]: any;
  type?: 'error' | 'success' | 'default';
}

const dragActive = css`
  border-color: #12a454;
`;

const dragReject = css`
  border-color: #e83f5b;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  max-width: 160px;
  max-height: 160px;
  border: 3px dashed #b9b9b9;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  transition: height 0.2s ease;

  ${(props: UploadProps): false | FlattenSimpleInterpolation => props.isDragActive && dragActive}

  ${(props: UploadProps): false | FlattenSimpleInterpolation => props.isDragReject && dragReject}
`;

const messageColors = {
  default: '#0c5c75',
  error: '#e83f5b',
  success: '#12a454',
};

export const UploadMessage = styled.p`
  display: flex;
  font-size: 1.2rem;
  line-height: 24px;
  flex-direction: column;
  text-align: center;

  color: ${({ type }: UploadProps) => messageColors[type || 'default']};

  justify-content: center;
  align-items: center;

  & small {
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;

    & > p {
      font-size: 0.8rem;
    }
  }
`;
