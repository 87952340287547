import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import AdmSignIn from '../pages/AdmSignIn';
import SignIn from '../pages/SignIn';
import Application from '../pages/application';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/admsignin" exact component={AdmSignIn} />
      <Route path="/app*" exact component={Application} isPrivate />
    </Switch>
  );
};

export default Routes;
