import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { Content } from '../../../layout/AdminToolContent/styles';

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearz = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-100px);
  }
  to {
    opacity: 1;
    transform: translateZ(0px);
  }
`;

export const GoBack = styled.div`
  display: flex;
  animation: ${appear} 0.6s;
  margin: -10px 0 10px 0;

  & button {
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #888888;

    & svg {
      font-size: 1.5em;
    }

    & p {
      font-size: 1em;
      font-weight: 500;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  justify-content: space-around;
  min-height: 683px;
  position: relative;
  overflow: hidden;

  & div.separator {
    background-color: ${shade(0, '#1B9EB2')};
    width: 10px;
    height: 100%;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;

    @media (min-width: 1385px) {
      display: flex;
    }
  }

  @media (max-width: 1385px) {
    min-height: 600px;
  }

  @media (max-width: 1385px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;

    & div.separator {
      width: 100%;
      height: 10px;
    }
  }

  @media (max-width: 1385px) {
    padding-top: 50px;
  }

  @media (max-width: 768px) {
    overflow: auto;
    padding-top: 0;
  }
`;

export const InfoSensor = styled.div`
  display: flex;
  width: 650px;
  height: 100%;
  animation: ${appear} 0.6s;
  padding: 0;
  justify-content: center;
  align-items: center;

  & form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: auto;
    border-radius: 20px;
    gap: 20px;
    padding: 20px;
    position: relative;

    & p {
      color: #0c5c75;
      font-weight: 600;
      font-size: 1.3em;
      margin-bottom: 10px;
    }

    & div.inputs {
      width: 100%;
      gap: 10px;
      border: none;

      & div.s2 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 10px;
        gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    & div.butts {
      width: 100%;
      display: flex;
      justify-content: space-around;
      border: none;

      & div {
        display: flex;
        gap: 20px;
      }

      & button {
        width: 210px;
        height: 30px;
        border-radius: 30px;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: #1b9eb2;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  @media (max-width: 1385px) {
    min-height: 400px;
  }

  @media (max-width: 1023px) {
    min-height: 400px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
`;

export const AlertEquationSensors = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 650px;
  height: 100%;
  animation: ${appear} 0.6s;
  padding: 0;
  padding-bottom: 150px;

  & h2 {
    color: #0c5c75;
    font-weight: 600;
    font-size: 1.3em;
  }

  & div.cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    min-height: 400px;
    padding-bottom: 50px;

    & div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding-left: 5px;
    }
  }

  & button {
    width: 100%;
    height: auto;
    padding: 20px;
    background-color: #ececec;
    border-radius: 10px;
    box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};
    position: relative;

    & span {
      width: 13px;
      height: 13px;
      border-radius: 13px;
      background-color: #76ee59;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    & span.disable {
      background-color: #ff0000;
    }

    & p {
      font-size: 1em;
      font-weight: 500;
      color: #000000;
      word-break: break-word;
    }
  }

  & button.equation {
    display: flex;
    align-items: center;

    & h3 {
      font-weight: 500;
      font-size: 1em;
      color: #1b9eb2;
    }

    & div {
      height: 100%;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    & div.start {
      width: 25%;
      padding: 0 10px;
    }

    & div.description {
      width: 50%;
      justify-content: space-between;
      padding: 0 10px;
    }

    & div.end {
      width: 25%;
      padding: 0 10px;
    }
  }

  & button.alarm {
    display: flex;
    align-items: center;

    & div {
      height: 100%;
      gap: 20px;
      padding: 0 10px;
    }

    & div.max-min {
      width: 25%;
    }

    & div.interval {
      width: 75%;
    }
  }

  @media (max-width: 1385px) {
    min-height: 500px;
  }

  @media (max-width: 1025px) {
    min-height: 500px;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-height: 600px;
    margin-top: 0;
  }
`;

export const ButtonExportCSV = styled.button`
  position: absolute;
  top: -32px;
  right: 0;
  font-weight: 500;
  background-color: #1b9eb2;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 5px;

  display: flex;
  justify-content: center;

  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    position: static;
    margin-top: 20px;
  }
`;

export const EditEquationPopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearz} 0.7s;
  z-index: 999;

  & form.form {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    border-radius: 20px;
    gap: 10px;
    padding: 10px 25px;
    position: relative;

    svg {
      color: #1b9eb2;
    }

    & button.close {
      background-color: transparent;
      width: 24px;
      height: 24px;
      color: #1b9eb2;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;

      & svg {
        font-size: 24px;
      }
    }

    & p {
      color: #000000;
      font-weight: 500;
      font-size: 1em;
      margin-bottom: 10px;
    }

    & div.description {
      width: 100%;
      gap: 10px;
    }

    & div.filter-equation {
      width: 100%;
      display: flex;

      & div.start {
        width: 25%;
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & div.filter-start {
          width: 136px;
          background-color: #ececec;
          box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};
          border-radius: 10px;
          padding: 10px;
        }
        @media (max-width: 650px) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      & div.equation {
        width: 50%;
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & p.description {
          font-size: 0.8em;
        }

        & div.formule {
          width: 100%;
          height: 100%;

          & div.container-input {
            height: 100px;
          }
        }
        @media (max-width: 650px) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      & div.end {
        width: 25%;
        padding-top: 20px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & div.filter-end {
          width: 136px;
          background-color: #ececec;
          box-shadow: -3px 3px 4px ${shade(0.3, '#ECECEC')};
          border-radius: 10px;
          padding: 10px;
        }
        @media (max-width: 650px) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }

      @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;
      }
    }

    & div.butts {
      width: 100%;
      display: flex;
      justify-content: end;
      gap: 20px;

      & div {
        display: flex;
        gap: 20px;
      }

      & button {
        width: 210px;
        height: 30px;
        border-radius: 30px;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: #1b9eb2;
      }
    }
    @media (max-width: 600px) {
      scale: 0.9;
    }
  }
`;

export const EditAlertPopup = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${appearz} 0.7s;
  z-index: 999;

  & form.form {
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    border-radius: 20px;
    gap: 10px;
    padding: 20px 25px;
    position: relative;

    & button.close {
      background-color: transparent;
      width: 24px;
      height: 24px;
      color: #1b9eb2;
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;

      & svg {
        font-size: 24px;
      }
    }

    & p {
      color: #000000;
      font-weight: 500;
      font-size: 1em;
      margin-bottom: 10px;
    }

    & div.container {
      width: 90%;
      gap: 50px;
      justify-content: space-between;

      & div {
        width: auto;
        align-items: center;
      }

      & div.slides {
        display: flex;
        gap: 30px;

        & div.slider {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    & button.submit {
      background-color: #1b9eb2;
      border-radius: 30px;
      width: 210px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
