import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface containerInterface {
  error: boolean;
}

export const Container = styled.div<containerInterface>`
  box-shadow: 0px 0px 10px 1px #e0e0e0;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  border: 2px solid #0c5c75;

  & div.eye {
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      color: #000000;
      margin: 0;
      scale: 1.2;
    }
  }

  & svg {
    background-color: transparent;
    min-width: 20px;
    margin-right: 15px;
    margin-left: 5px;
  }

  & input {
    flex: 1;
    min-width: 105px;
    background: transparent;
    border: none;
    color: #0c5c75;
    &::placeholder {
      color: #a7d1e1;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & span {
    width: 100%;
    margin-right: 4px;
    text-align: right;
  }

  ${props =>
    props.error &&
    css`
      background-color: transparent;
      border-color: #c53030;
    `}
`;

export const Error = styled(Tooltip)`
  display: flex;
  position: absolute;
  background-color: transparent;
  height: 40px;
  align-items: center;
  margin-left: -40px;
  max-width: 40px;
  padding-left: 10px;

  svg {
    margin: 0;
  }

  span {
    border: none;
    background: #c53030;
    color: #fff;
    margin-left: 160px;

    &::before {
      border-color: none;
    }
  }
`;
