import styled from 'styled-components';

import backgroundWeb from '../../assets/login-back-user-web.jpg';
import backgroundTablet from '../../assets/login-back-user-tablet.jpg';
import backgroundmobile from '../../assets/login-back-user-mobile.jpg';

export const Content = styled.div`
  display: flex;
  width: 550px;
  justify-content: space-between;
  align-items: center;
  & div.logo {
    img {
      width: 180px;
    }
  }

  & form {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 100%;
      & + div {
        margin-top: 10px;
      }
    }
    & a {
      width: 100%;
      color: #a7d1e1;
      font-size: 0.7em;
      text-align: end;
      margin: 5px 0;
      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
    & p.error {
      color: #c24727;
      font-size: 1em;
      text-align: center;
      font-weight: 500;
    }
    & button {
      margin-top: 10px;
      width: 70%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;

    & div.logo {
      img {
        margin-bottom: 20px;
      }
    }

    & form {
      width: 60%;
    }
  }
  @media (max-width: 768px) {
    & div.logo {
      img {
      }
    }
    & form {
      width: 62%;
    }
  }
`;

export const Background = styled.div`
  height: 100dvh;
  flex: 1;
  display: flex;
  background: url(${backgroundWeb}) no-repeat center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  & a.admPage {
    position: absolute;
    bottom: 10px;
    right: 20px;
    text-align: end;
    width: 135px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #0c5c75;
    padding-right: 10px;
    color: #0c5c75;
    transition: 0.3s;
    &:hover {
      border: 2px solid #0c5c75;
    }
  }

  @media (max-width: 1024px) {
    background: url(${backgroundTablet}) no-repeat center;
    background-size: cover;
  }
  @media (max-width: 768px) {
    background: url(${backgroundmobile}) no-repeat center;
    background-size: cover;
  }
`;
