import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { BsPersonFill, BsLockFill } from 'react-icons/bs';

import { AxiosError } from 'axios';
import getValidationErrors from '../../utils/getValidationsErrors';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import Button from '../../components/Button';
import Input from '../../components/Input';

import logo from '../../assets/logo.png';

import { Background, Content } from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const AdminSigin: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { signInAdm } = useAuth();

  const [loading, setLoading] = useState(false);

  const submitHandler = useCallback(
    async (data: SignInFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        return await signInAdm({ email: data.email, password: data.password });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return formRef.current?.setErrors(errors);
        }

        return addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, signInAdm],
  );

  return (
    <Background>
      <Content>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Form ref={formRef} onSubmit={submitHandler}>
          <Input icon={BsPersonFill} size={20} name="email" type="text" placeholder="Email" />
          <Input icon={BsLockFill} size={20} name="password" type="password" placeholder="Senha" />

          <Button name="login" isLoading={loading}>
            {loading ? 'autenticando...' : 'Entrar'}
          </Button>
        </Form>
      </Content>
      <Link to="/" className="admPage">
        Entrar como
        <br />
        <strong> Usuário</strong>
      </Link>
    </Background>
  );
};
export default AdminSigin;
