import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    outline:0;
  }

  body {
    background: #FFF ;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility !important;
  }

  body, input, button {
    font: 16px "Montserrat", sans-serif;
    font-weight:300;
  }

  h1,h2,h3,h4,strong{
    font-weight: 500;
  }

  a{
    text-decoration:none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    border: 0;
  }

  @media (max-width: 1024px) {
    body, input, button {
    font: 14px "Montserrat", sans-serif;
    font-weight:300;
  }
  }
  @media (max-width: 768px) {
    body, input, button {
    font: 12px "Montserrat", sans-serif;
    font-weight:300;
  }
  }

  .exitPopUp {
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #FFF;
    box-shadow: -5px 5px 4px 0px rgba(0, 0, 0, 0.25);

    p {
      color: #000;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 240% */
    }

    > section {
        display: flex;
        gap: 10px;

        > button {
            padding: 5px 10px;
            background: #d9d9d9;

            :hover {
              opacity: 0.7;
              transition: opacity 0.2s ease;
            }
        }

        > button:last-of-type {
            color: white;
            background: #FF0000;
            font-weight: bold;
        }
    }
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`;
