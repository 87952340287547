import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useParams, useHistory } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { MdEmail, MdError } from 'react-icons/md';

import { ISensor } from '../../../../../interfaces';
import { FormHandles } from '@unform/core';
import { AxiosError } from 'axios';
import { Form } from '@unform/web';
import { Grid, Tooltip } from '@material-ui/core';
import PageTitle from '../../../../../components/PageTitle';
import { useToast } from '../../../../../hooks/toast';
import api from '../../../../../services/api';
import * as S from './styles';
import getValidationErrors from '../../../../../utils/getValidationsErrors';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import Slider from '../../../../../components/Slider';
import Button from '../../../../../components/Button';
import { Advice } from '../../../../../components/Advice';
import { TiDownload } from 'react-icons/ti';
import DownloadCSV from '../../../components/DownloadCSV';
import { FaCircleQuestion } from 'react-icons/fa6';

type UpdateSensorFormData = {
  id?: string;
  sensor?: string;
  port?: number;
  unit_of_measurement?: string;
  is_enabled?: boolean;
};

type UpdateEquationFormData = {
  id: string;
  description: string;
  equation: string;
  is_enabled: boolean;
  input: number[];
  output: number[];
};

type UpdateLimitFormData = {
  limit_id: string;
  max: number;
  min: number;
  is_enabled: boolean;
  email_alert: boolean;
  waiting_time: number;
};

const EditSensor: React.FC = () => {
  const [editEquation, setEditEquation] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [sensor, setSensor] = useState<ISensor>({} as ISensor);
  const [error, setError] = useState('');

  const [showDownloadData, setShowDownloadData] = useState(false);

  const { addToast } = useToast();
  const params = useParams() as { id: string };
  const history = useHistory();
  const equationFormRef = useRef<FormHandles>(null);
  const alertFormRef = useRef<FormHandles>(null);
  const sensorFormRef = useRef<FormHandles>(null);

  const searchSensor = useCallback(async () => {
    try {
      const { data } = await api.get<ISensor>('/sensors', { params: { sensor_id: params.id } });

      if (!data?.id) {
        setError('Dispositivo não encontrado');

        return;
      }

      setSensor(data);
    } catch (error) {
      const message = (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`;

      addToast({
        type: 'error',
        title: 'Erro ao buscar sensor',
        description: message,
      });

      setError(message);
    }
  }, [addToast]);

  useEffect(() => {
    searchSensor();
  }, [searchSensor]);

  const updateSensorFormHandler = useCallback(
    async (formData: UpdateSensorFormData) => {
      try {
        sensorFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          sensor: Yup.string().required(),
          unit_of_measurement: Yup.string().required(),
          port: Yup.string().required(),
          is_enabled: Yup.string().required(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { sensor, unit_of_measurement, port, is_enabled } = formData;

        const dataToSend = {
          id: params.id,
          name: sensor,
          port: Number(port),
          is_enabled,
          unit_of_measurement,
        };

        await api.put('/sensors', dataToSend);

        addToast({
          type: 'success',
          title: 'Sensor atualizado!',
          description: 'Sensor atualizado com sucesso!',
        });

        return searchSensor();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          return sensorFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao atualizar sensor',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      }
    },
    [addToast, params.id, searchSensor],
  );

  const updateEquationFormHandler = useCallback(
    async (formData: UpdateEquationFormData) => {
      try {
        equationFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          description: Yup.string().required(),
          equation: Yup.string().required(),
          is_enabled: Yup.boolean().required(),
          input: Yup.array(Yup.number()).required(),
          output: Yup.array(Yup.number()).required(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        await api.put('/equation', { ...formData, id: sensor.equation.id });

        sensorFormRef.current?.reset();

        addToast({
          type: 'success',
          title: 'Equação atualizada!',
          description: 'Equação atualizada com sucesso!',
        });

        setEditEquation(false);
        searchSensor();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          return equationFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao atualizar equação',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      }
    },
    [addToast, params.id, searchSensor, sensor],
  );

  const limitFormHandler = useCallback(
    async (formData: UpdateLimitFormData) => {
      try {
        alertFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          max: Yup.string().required(),
          min: Yup.string().required(),
          waiting_time: Yup.string().required(),
          email_alert: Yup.boolean().required(),
          is_enabled: Yup.boolean().required(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        await api.put('/limits', { ...formData, limit_id: sensor.limit.id });

        sensorFormRef.current?.reset();

        addToast({
          type: 'success',
          title: 'Alerta atualizado!',
          description: 'Alerta atualizado com sucesso!',
        });

        setEditAlert(false);
        searchSensor();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          return alertFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao atualizar alerta',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      }
    },
    [addToast, params.id, searchSensor, sensor],
  );

  const infoSensor = useMemo(() => {
    return (
      <Form className="form" ref={sensorFormRef} onSubmit={updateSensorFormHandler}>
        <S.ButtonExportCSV type="button" onClick={() => setShowDownloadData(true)}>
          <TiDownload size="1.5rem" />
          Exportar Alertas
        </S.ButtonExportCSV>

        <Grid container spacing={2} className="inputs">
          <Grid item xs={12}>
            <p>Sensor</p>
            <Input hideErrorIcon name="sensor" type="text" defaultValue={sensor.name} />
          </Grid>
          <div className="s2">
            <Grid item xs={10}>
              <p>Unidade de medida</p>
              <Input hideErrorIcon name="unit_of_measurement" type="text" defaultValue={sensor.unit_of_measurement} />
            </Grid>
            {sensor.port && (
              <Grid item xs={8}>
                <p>Porta</p>
                <Select
                  name="port"
                  defaultValue={sensor.port}
                  data={[
                    { id: '1', name: '1' },
                    { id: '2', name: '2' },
                    { id: '3', name: '3' },
                    { id: '4', name: '4' },
                    { id: '5', name: '5' },
                  ]}
                />
              </Grid>
            )}
          </div>
        </Grid>

        <div className="butts">
          <Grid item xs={8}>
            <p>Status</p>
            <Slider name="is_enabled" checked={sensor.is_enabled} />
          </Grid>

          <Button type="submit">Salvar alterações</Button>
        </div>
      </Form>
    );
  }, [updateSensorFormHandler, sensor]);

  const alertEquationSensor = useMemo(() => {
    return (
      <div className="cards">
        <Grid item xs={12}>
          <h2>Equação</h2>
          <button type="button" className="equation" onClick={() => setEditEquation(true)}>
            <span className={sensor.equation?.is_enabled ? '' : 'disable'} />

            <div className="start">
              <h3>Filtro de Entrada</h3>
              <h3>De</h3>
              <p>{sensor.equation?.input[0]}</p>
              <h3>Até</h3>
              <p>{sensor.equation?.input[1]}</p>
            </div>
            <div className="description">
              <h3>Descrição</h3>
              <p>{sensor.equation?.description}</p>
              <p>{sensor.equation?.equation}</p>
            </div>
            <div className="end">
              <h3>Filtro de Saida</h3>
              <h3>De</h3>
              <p>{sensor.equation?.output[0]}</p>
              <h3>Até</h3>
              <p>{sensor.equation?.output[1]}</p>
            </div>
          </button>
        </Grid>

        <Grid item xs={12}>
          <h2>Alarme</h2>
          <button type="button" className="alarm" onClick={() => setEditAlert(true)}>
            <span className={sensor.limit?.is_enabled ? '' : 'disable'} />

            <div className="max-min">
              <p>{`max: ${sensor.limit?.max}`}</p>
              <p>{`min: ${sensor.limit?.min}`}</p>
            </div>
            <div className="interval">
              <p>{`Intervalo de tempo: ${sensor.limit?.waiting_time} seg`}</p>
              <p>{`Aviso por e-mail: ${sensor.limit?.email_alert ? 'Ativado' : 'Desativado'}`}</p>
            </div>
          </button>
        </Grid>
      </div>
    );
  }, [sensor]);

  const equationForm = useMemo(() => {
    return (
      <Form className="form" ref={equationFormRef} onSubmit={updateEquationFormHandler}>
        <button type="button" className="close" onClick={() => setEditEquation(false)}>
          <AiOutlineClose />
        </button>
        <Grid item className="description">
          <p>Descrição</p>
          <Input hideErrorIcon name="description" placeholder="Breve descrição da equação" defaultValue={sensor.equation?.description} />
        </Grid>

        <Grid container className="filter-equation">
          <Grid className="start">
            <Grid container style={{ gap: 4, justifyContent: 'center' }}>
              <p>Filtro de Entrada</p>
              <Tooltip style={{ zIndex: 9999 }} title="O filtro de entrada limita o valor máximo ou mínimo recebido pelo IoT.">
                <div>
                  <FaCircleQuestion />
                </div>
              </Tooltip>
            </Grid>
            <Grid className="filter-start">
              <p>De</p>
              <Input hideErrorIcon name="input[0]" type="number" defaultValue={sensor.equation?.input[0]} />
              <p>Até</p>
              <Input hideErrorIcon name="input[1]" type="number" defaultValue={sensor.equation?.input[1]} />
            </Grid>
          </Grid>

          <Grid className="equation">
            <Grid container style={{ gap: 4, justifyContent: 'center' }}>
              <p>Equação</p>
              <Tooltip
                style={{ zIndex: 9999 }}
                title='A equação recebe o valor limitado do filtro de entrada, realiza o cálculo e passa para o filtro de saída. Utilize apenas "x" caso não deseje aplicar um cálculo.'
              >
                <div>
                  <FaCircleQuestion />
                </div>
              </Tooltip>
            </Grid>
            <Grid item className="equation">
              <p className="description">{`"x" será substituído com o valor do sensor no tempo de execução.`}</p>
              <Input hideErrorIcon name="equation" placeholder='("x" / 2) + 2' defaultValue={sensor.equation?.equation} />
            </Grid>
          </Grid>

          <Grid className="end">
            <Grid container style={{ gap: 4, justifyContent: 'center' }}>
              <p>Filtro de Saída</p>
              <Tooltip style={{ zIndex: 9999 }} title="O filtro de saída limita o valor máximo ou mínimo final calculado da equação.">
                <div>
                  <FaCircleQuestion />
                </div>
              </Tooltip>
            </Grid>
            <Grid className="filter-end">
              <p>De</p>
              <Input hideErrorIcon name="output[0]" type="number" defaultValue={sensor.equation?.output[0]} />
              <p>Até</p>
              <Input hideErrorIcon name="output[1]" type="number" defaultValue={sensor.equation?.output[1]} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="butts">
          <Grid item>
            <p>Status</p>
            <Slider name="is_enabled" checked={sensor.equation?.is_enabled} />
          </Grid>
          <Button type="submit">Salvar alterações </Button>
        </Grid>
      </Form>
    );
  }, [updateEquationFormHandler, sensor]);

  const limitForm = useMemo(() => {
    return (
      <Form className="form" ref={alertFormRef} onSubmit={limitFormHandler}>
        <button type="button" className="close" onClick={() => setEditAlert(false)}>
          <AiOutlineClose />
        </button>
        <Grid container className="container">
          <Grid>
            <p>Max</p>
            <Input hideErrorIcon name="max" defaultValue={sensor.limit?.max} />
          </Grid>
          <Grid>
            <p>Min</p>
            <Input hideErrorIcon name="min" defaultValue={sensor.limit?.min} />
          </Grid>
          <Grid>
            <p>Tempo de espera (seg)</p>
            <Input hideErrorIcon name="waiting_time" defaultValue={sensor.limit?.waiting_time} />
          </Grid>
          <div className="slides">
            <Grid className="slider">
              <p>Status</p>
              <Slider name="is_enabled" checked={sensor.limit?.is_enabled} />
            </Grid>
            <Grid className="slider">
              <p>Alerta por e-mail </p>
              <Slider name="email_alert" checked={sensor.limit?.email_alert} />
            </Grid>
          </div>
        </Grid>

        <Button type="submit" className="submit">
          Salvar
        </Button>
      </Form>
    );
  }, [limitFormHandler, sensor]);

  return (
    <>
      <S.GoBack>
        <button type="button" onClick={() => history.goBack()}>
          <RiArrowLeftSLine />
          <p>Voltar</p>
        </button>
      </S.GoBack>

      {!!error ? (
        <Advice text={error} icon={MdError} />
      ) : (
        <>
          {!(editEquation || editAlert) && sensor.id && (
            <>
              <PageTitle sub="Sensor" title="Configurar" />
              <S.Container>
                <S.InfoSensor>{infoSensor}</S.InfoSensor>
                <div className="separator" />
                <S.AlertEquationSensors>{alertEquationSensor}</S.AlertEquationSensors>
              </S.Container>
            </>
          )}
          {editEquation && <S.EditEquationPopup>{equationForm}</S.EditEquationPopup>}
          {editAlert && <S.EditAlertPopup>{limitForm}</S.EditAlertPopup>}
        </>
      )}

      <DownloadCSV show={showDownloadData} closeFunction={setShowDownloadData} sensorId={params.id} csvUrl="alerts/csv" />
    </>
  );
};

export default EditSensor;
