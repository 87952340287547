import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px 3px 3px 3px;
  background-color: transparent;

  strong {
    color: #1b9eb2;
    font-weight: bold;
  }

  span {
    color: #1b9eb2;
    font-weight: normal;
  }

  & + & {
    border-top: 1px solid #1b9eb2;
  }

  :hover {
    transition: background-color 0.2s ease;
    background-color: ${lighten(0.2, '#e3eff4')};
  }
`;

export const Dot = styled.i`
  border-radius: 100px;
  background-color: #ff7a00;
  width: 5px;
  height: 5px;
  content: '';
`;
