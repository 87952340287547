import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api, { configureApi } from '../services/api';
import { useSelectInfo } from './selectInfo';
import { IDevice, ISensor } from '../interfaces';

export interface IMessage {
  id: string;
  device_id: string;
  user_id: string;
  message: string;
  not_readed: boolean;
}

export interface IIoTData {
  id: string;
  device_id: string;
  volumeTratado: number;
  consumoAgua: number;
  consumoEnergia: number;
  pH: number;
  Turbidez: number;
  Cor: number;
  Condutividade: number;
  Temperatura: number;
  Oxigenio: number;
  Cloro: number;
  created_at: Date;
}

export enum UserRolesEnum {
  ADMIN = 'admin',
  PROJECT = 'project',
  OPERATOR = 'operation',
}

export interface IUser {
  addressUser: {
    cep: string;
    city: string;
    complement: string;
    created_at: string;
    district: string;
    id: string;
    number: string;
    street: string;
    uf: string;
  };
  devices: IDevice[];
  sensors: ISensor[];
  messages: IMessage[];
  manager_user: string;
  role: UserRolesEnum;
  avatar: string;
  avatar_url: string | null;
  email: string;
  id: string;
  name: string;
  operations?: IUser[];
  manager?: IUser;
}
interface AuthState {
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  updateUser(user: IUser): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInAdm(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { selectUser, selectDevice } = useSelectInfo();

  useEffect(() => {
    const user = localStorage.getItem('@PWtech:user');
    if (user) {
      const parsedUser: IUser = JSON.parse(user);

      if (parsedUser.role !== 'admin') {
        selectUser(parsedUser);
        selectDevice(parsedUser?.devices[0]?.id);
      }
    }
  }, [selectDevice, selectUser]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@PWtech:token');
    localStorage.removeItem('@PWtech:user');

    setData({} as AuthState);
  }, []);

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@PWtech:token');
    const user = localStorage.getItem('@PWtech:user');

    if (token && user) {
      configureApi(token, signOut);

      const parsedUser = JSON.parse(user);

      return { user: parsedUser };
    }

    return {} as AuthState;
  });

  const updateUser = useCallback(async user => {
    localStorage.setItem('@PWtech:user', JSON.stringify(user));
    setData({ user });
  }, []);

  const signIn = useCallback(
    async ({ email, password }) => {
      const response = await api.post('sessions', { email, password });

      const { token, user } = response.data;

      localStorage.setItem('@PWtech:token', token);
      localStorage.setItem('@PWtech:user', JSON.stringify(user));

      configureApi(token, signOut);

      await selectUser(user);
      await selectDevice(user?.devices[0]?.id);

      setData({ user });
    },
    [selectUser, selectDevice],
  );

  const signInAdm = useCallback(
    async ({ email, password }) => {
      const response = await api.post('sessions/adm', {
        email,
        password,
      });

      const { token, user } = response.data;

      localStorage.setItem('@PWtech:token', token);
      localStorage.setItem('@PWtech:user', JSON.stringify(user));

      configureApi(token, signOut);

      await selectUser({} as IUser);
      await selectDevice('');

      setData({ user });
    },
    [selectUser, selectDevice],
  );

  return <AuthContext.Provider value={{ user: data.user, signIn, signOut, signInAdm, updateUser }}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
