import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 30px;
  min-width: 350px;

  border-radius: 10px;
  background: #e3eff4;
  -webkit-box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 13px 24px -8px rgba(0, 0, 0, 0.26);

  strong {
    color: #0c5c75;
  }
`;
