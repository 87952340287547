import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { AxiosError } from 'axios';
import { Link, useParams } from 'react-router-dom';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import { HiOutlineTrash } from 'react-icons/hi';
import { FaBatteryEmpty, FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters, FaEdit } from 'react-icons/fa';
import { FiAlertTriangle } from 'react-icons/fi';
import { Grid, Tooltip } from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';
import { MdError } from 'react-icons/md';

import PageTitle from '../../../../../components/PageTitle';
import * as S from './styles';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';
import getValidationErrors from '../../../../../utils/getValidationsErrors';
import { IAlert, IDevice, ISensor } from '../../../../../interfaces';
import api from '../../../../../services/api';
import { useToast } from '../../../../../hooks/toast';
import { Advice } from '../../../../../components/Advice';
import Slider from '../../../../../components/Slider';
import { HiBattery0, HiBattery100, HiBattery50 } from 'react-icons/hi2';

type DeviceWithAlerts = {
  alerts: IAlert[];
  batteryAlerts: {
    id: string;
    message: string;
    timestamp: Date;
  }[];
} & IDevice;

interface IBatteryData {
  id: string;
  device_id: string;
  value: number;
}

interface ICardAlert {
  id: string;
  timestamp: Date;
  message: string;
}

const EditDevices: React.FC = () => {
  const { addToast } = useToast();
  const params = useParams() as { id: string };

  const larguraTela = window.innerWidth;
  const alturaTela = window.innerHeight;

  const [device, setDevice] = useState<DeviceWithAlerts>({} as DeviceWithAlerts);
  const [battery, setBattery] = useState<number | undefined>(undefined);
  const [alerts, setAlerts] = useState<ICardAlert[]>([]);
  const [sensors, setSensors] = useState<ISensor[]>([]);
  const [deleteSensor, setDeleteSensor] = useState('');
  const [newSensor, setNewSensor] = useState(false);
  const [error, setError] = useState('');
  const sensorFormRef = useRef<FormHandles>(null);

  const searchDevice = useCallback(async () => {
    try {
      const { data } = await api.get<DeviceWithAlerts>(`devices/${params.id}`);

      const sensorAlerts = data.alerts.map(item => ({ id: item.id, timestamp: new Date(item.timestamp), message: item.message }));
      const batteryAlerts = data.batteryAlerts.map(item => ({ id: item.id, timestamp: new Date(item.timestamp), message: item.message }));
      const allAlerts = [...sensorAlerts, ...batteryAlerts].sort((a, b) => {
        return b.timestamp.getTime() - a.timestamp.getTime();
      });

      setSensors(data.sensors);

      setAlerts(allAlerts);
      setDevice(data);
      setBatteryAlert(data.battery_alert);
      const { data: batteryData } = await api.get<IBatteryData>(`battery`, {
        params: { device_id: data.id },
      });

      setBattery(batteryData.value);
    } catch (error) {
      const message = (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`;

      addToast({
        type: 'error',
        title: 'Erro ao buscar dispositivo',
        description: message,
      });

      setError(message);
    }
  }, [addToast]);

  useEffect(() => {
    searchDevice();
  }, [searchDevice]);

  const [batteryAlert, setBatteryAlert] = useState<boolean>(device.battery_alert);

  const handleDeleteSensor = useCallback(async (id: string) => {
    try {
      await api.delete('/sensors', { params: { id } });

      setDeleteSensor('');

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Sensor deletado com sucesso!',
      });

      searchDevice();
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar sensor',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    }
  }, []);

  const sensorFormHandler = useCallback(
    async formData => {
      try {
        sensorFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          sensor: Yup.string().required(),
          unit: Yup.string().required(),
          port: Yup.string().required(),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const { sensor, unit, port } = formData;
        const dataToSend = {
          name: sensor,
          port: Number(port),
          unit_of_measurement: unit,
          device_id: params.id,
        };

        sensorFormRef.current?.reset();

        await api.post('/sensors', dataToSend);

        addToast({
          type: 'success',
          title: 'Sensor criado!',
          description: 'Sensor criado com sucesso!',
        });

        setNewSensor(false);
        return searchDevice();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          return sensorFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao criar novo sensor',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      }
    },
    [addToast, params.id, searchDevice],
  );

  const fakeDevice = {
    id: '1',
    name: 'Dispositivo X',
    user: { name: 'Usuário X' },
    mac_address: '00:1B:44:11:3A:B7',
    battery: 75,
    limit: 80,
  };

  const deviceFormRef = useRef<FormHandles>(null);
  const [loader, setLoader] = useState(false);

  const updateDeviceFormHandler = useCallback(
    async formData => {
      console.log('Submeteu');
      setLoader(true);
      console.log('Data: ', formData);
      try {
        console.log('Tentando...');
        deviceFormRef.current?.setErrors({});

        const defaultSchema = Yup.object().shape({
          battery_alert: Yup.boolean().required(),
        });

        const schemaWithAlertFields = Yup.object().shape({
          battery_alert: Yup.boolean().required(),
          battery_email_alert: Yup.boolean().required(),
          battery_alert_limit: Yup.number()
            .required()
            .min(0, 'O valor mínimo do limite de bateria é 0.')
            .max(100, 'O valor máximo do limite de bateria é 100.'),
        });

        if (formData.battery_alert) {
          await schemaWithAlertFields.validate(formData, {
            abortEarly: false,
          });
        } else {
          await defaultSchema.validate(formData, {
            abortEarly: false,
          });
        }

        const { battery_alert, battery_email_alert, battery_alert_limit } = formData;
        const dataToSend = {
          battery_alert,
          battery_email_alert: battery_alert ? battery_email_alert : undefined,
          battery_alert_limit: battery_alert ? battery_alert_limit : null,
        };

        await api.put(`/devices/${device.id}`, dataToSend);

        deviceFormRef.current?.reset();

        addToast({
          type: 'success',
          title: 'Dispositivo atualizado!',
          description: 'Dispositivo atualizado com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          addToast({
            type: 'error',
            title: 'Erro ao atualizar dispositivo',
            description: `${error.message}`,
          });

          return deviceFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao atualizar dispositivo',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        setLoader(false);
      }
    },
    [addToast, device, batteryAlert],
  );

  const formRef = useRef<FormHandles>(null);

  const infoDevice = useMemo(() => {
    const submitForm = () => {
      if (formRef.current) {
        formRef.current.submitForm();
      }
    };

    return (
      <>
        <div>
          <Form ref={formRef} className="device" onSubmit={updateDeviceFormHandler}>
            <div className="device">
              <span>
                <h2>Nome</h2>
                <p>{device.name}</p>
              </span>
              <span>
                <h2>Usuário</h2>
                <p>{device.user?.name || ''}</p>
              </span>
              <span>
                <h2>Endereço Mac</h2>
                <p>{device.mac_address}</p>
              </span>

              <S.BatteryLevelLabelContainer>
                <h2>Nível da bateria</h2>
                <div>
                  {battery && battery >= 90 && <HiBattery100 size={35} />}
                  {battery && battery >= 15 && battery < 90 && <HiBattery50 size={35} />}
                  {battery && battery < 15 && <HiBattery0 size={35} />}
                  {battery ? (
                    <S.BatteryLevelLabel lowLevel={!!device.battery_alert && !!device.battery_alert_limit && battery < device.battery_alert_limit}>
                      {battery}%
                    </S.BatteryLevelLabel>
                  ) : (
                    <S.BatteryLevelLabel>-</S.BatteryLevelLabel>
                  )}
                </div>
              </S.BatteryLevelLabelContainer>
              <span>
                <h2>Alerta de bateria</h2>
                <S.Span>
                  <Slider
                    name="battery_alert"
                    checked={batteryAlert}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setBatteryAlert(e.target.checked);

                      if (!e.target.checked || formRef.current?.getFieldValue('battery_alert_limit')) submitForm();
                    }}
                  />
                </S.Span>
              </span>
              <span />
              <S.Span height={batteryAlert ? 6 : 0}>
                <h2>Envio de alerta por e-mail</h2>
                <Slider
                  name="battery_email_alert"
                  checked={device.battery_email_alert || false}
                  onChange={() => {
                    if (formRef.current?.getFieldValue('battery_alert_limit')) submitForm();
                  }}
                />
              </S.Span>
              <S.Span height={batteryAlert ? 6 : 0}>
                <h2>Limite da bateria</h2>
                <Input
                  name="battery_alert_limit"
                  type="number"
                  placeholder="15"
                  defaultValue={device.battery_alert_limit}
                  showPercentage
                  disabled={loader}
                  onBlur={e => {
                    if (e.target.value !== '') submitForm();
                  }}
                />
              </S.Span>
            </div>
          </Form>
        </div>
        <S.AlertsContainer>
          <h3>Histórico de alertas</h3>
          <ul>
            {alerts.length > 0 ? (
              alerts.map((alert, index) => (
                <li key={`${alert.id}-${index}`}>
                  <p className="date">{format(new Date(alert.timestamp), 'dd/MM/yyyy HH:mm')}</p>
                  <p>{alert.message}</p>
                </li>
              ))
            ) : (
              <h3>Nenhum alerta encontrado</h3>
            )}
          </ul>
        </S.AlertsContainer>
      </>
    );
  }, [alerts, fakeDevice, batteryAlert, device]);

  const infoSensors = useMemo(() => {
    return (
      <>
        <div className="results">
          <div className="sensorsLabel">
            <h3>Sensores</h3>
            <button type="button" className="add" onClick={() => setNewSensor(true)}>
              <AiOutlinePlus />
            </button>
          </div>
          <ul>
            {sensors.length > 0 ? (
              sensors.map(sensor => (
                <div key={sensor.id} className="card">
                  <div className="infos">
                    <span className="sensor">
                      <h2>Sensor</h2>
                      <p>{sensor.name}</p>
                    </span>
                    <span className="port">
                      <h2>Porta</h2>
                      <p>{sensor.port}</p>
                    </span>
                    <span className="unit">
                      <h2>Unidade de medida</h2>
                      <p>{sensor.unit_of_measurement}</p>
                    </span>
                  </div>
                  <div className="edit">
                    <Link className="edit" to={`sensor/${sensor.id}`}>
                      <FaEdit />
                    </Link>
                    <button className="trash" onClick={() => setDeleteSensor(sensor.id)} type="button">
                      <HiOutlineTrash />
                    </button>
                    <Tooltip title={`Sensor ${sensor.is_enabled ? 'ativado' : 'desativado'}`}>
                      <span className={sensor.is_enabled ? 'active' : 'desative'} />
                    </Tooltip>
                  </div>
                </div>
              ))
            ) : (
              <h3>Nenhum sensor encontrado</h3>
            )}
          </ul>
        </div>
      </>
    );
  }, [sensors]);

  const newSensorForm = useMemo(
    () => (
      <Form className="form" ref={sensorFormRef} onSubmit={sensorFormHandler}>
        <button className="close" type="button" onClick={() => setNewSensor(false)}>
          <AiOutlineClose />
        </button>
        <Grid container spacing={2} className="inputs">
          <Grid item xs={12}>
            <p>Sensor</p>
            <Input name="sensor" type="text" placeholder="pH" />
          </Grid>
          <div className="s2">
            <Grid item xs={10}>
              <p>Unidade de medida</p>
              <Input name="unit" type="text" placeholder="H3O+" />
            </Grid>
            <Grid item xs={4}>
              <p>Porta</p>
              <Select
                name="port"
                data={[
                  { id: '1', name: '1' },
                  { id: '2', name: '2' },
                  { id: '3', name: '3' },
                  { id: '4', name: '4' },
                  { id: '5', name: '5' },
                ]}
              />
            </Grid>
          </div>
        </Grid>

        <div className="butts">
          <Button type="submit">Salvar alterações</Button>
        </div>
      </Form>
    ),
    [sensorFormHandler],
  );

  return (
    <>
      <S.GoBack>
        <Link to="/app/device" className="back">
          <RiArrowLeftSLine />
          <p>Voltar</p>
        </Link>
      </S.GoBack>
      {/* {!!error ? (
        <Advice text={error} icon={MdError} />
      ) : ( */}
      <>
        {!(deleteSensor || newSensor) && (
          <>
            <PageTitle sub="Dispositivo" title="Gerenciar" />
            <S.Container>
              <S.InfoDevice show>{infoDevice}</S.InfoDevice>
              {/* <S.InfoDevice show> {staticInfoDevice} </S.InfoDevice> */}
              <div className="separator" />
              <S.InfoSensors show>{infoSensors}</S.InfoSensors>
              {/* <S.InfoSensors show> {staticInfoSensors} </S.InfoSensors> */}
            </S.Container>
          </>
        )}
        {!!deleteSensor && (
          <S.DeletePopup>
            <div>
              <FiAlertTriangle size={30} />
              <p>Deseja deletar o sensor?</p>
              <div className="buttons">
                <Button className="cancel" onClick={() => setDeleteSensor('')}>
                  Cancelar
                </Button>
                <Button className="delete" onClick={() => handleDeleteSensor(deleteSensor)}>
                  Deletar
                </Button>
              </div>
            </div>
          </S.DeletePopup>
        )}
        {newSensor && <S.NewSensorPopup>{newSensorForm}</S.NewSensorPopup>}
      </>
      {/* )} */}
    </>
  );
};

export default EditDevices;
