import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 50px;
  & h2 {
    color: #0c5c75;
    font-size: 2rem;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    & img {
      width: 100px;
    }
    & h2 {
      font-size: 1em;
    }
  }
`;
