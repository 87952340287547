import styled from 'styled-components';

import backgroundWeb from '../../assets/login-back-adm-web.jpg';
import backgroundTablet from '../../assets/login-back-adm-tablet.jpg';
import backgroundmobile from '../../assets/login-back-adm-mobile.jpg';

export const Content = styled.div`
  display: flex;
  width: 550px;
  justify-content: space-between;
  align-items: center;
  & div.logo {
    img {
      width: 180px;
    }
  }

  & form {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 100%;
      box-shadow: none;
      & > input {
        color: #fff;
        &::placeholder {
          color: #bfe4ea;
        }
      }
      & + div {
        margin-top: 10px;
      }

      & > svg {
        color: #fff;
      }
    }

    & button {
      margin-top: 30px;
      width: 70%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;

    & div.logo {
      img {
        margin-bottom: 20px;
      }
    }

    & form {
      width: 60%;
      & > div {
        & > input {
          color: #0c5c75;
          &::placeholder {
            color: #1b9eb2;
          }
        }
        & > svg {
          color: #0c5c75;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & div.logo {
      img {
      }
    }
    & form {
      width: 62%;
    }
  }
`;

export const Background = styled.div`
  height: 100dvh;
  flex: 1;
  display: flex;
  background: url(${backgroundWeb}) no-repeat center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  & a.admPage {
    position: absolute;
    bottom: 10px;
    right: 20px;
    text-align: end;
    width: 135px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 2px solid #fff;
    padding-right: 10px;
    color: #fff;
    transition: 0.3s;
    &:hover {
      border: 2px solid #fff;
    }
  }

  @media (max-width: 1024px) {
    background: url(${backgroundTablet}) no-repeat center;
    background-size: cover;
  }
  @media (max-width: 768px) {
    background: url(${backgroundmobile}) no-repeat center;
    background-size: cover;
  }
`;
