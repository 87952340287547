import axios, { AxiosError } from 'axios';

if (!process.env.REACT_APP_API_URL) throw new Error('REACT_APP_API_URL environment variable not defined.');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const configureApi = (token: string, signOut: () => void) => {
  api.defaults.headers.authorization = `Bearer ${token}`;
  api.interceptors.response.use(
    response => {
      return response;
    },
    (error: AxiosError) => {
      if (error?.response?.data.message === 'Invalid JWT token!!!') {
        signOut();
      }
      return Promise.reject(error);
    },
  );
};

export default api;
