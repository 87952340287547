import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Grid, Modal } from '@material-ui/core';
import { FaEdit } from 'react-icons/fa';
import { BiSearchAlt } from 'react-icons/bi';

import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import PageTitle from '../../../../../components/PageTitle';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import { useToast } from '../../../../../hooks/toast';
import { IUser, useAuth } from '../../../../../hooks/auth';

import getValidationErrors from '../../../../../utils/getValidationsErrors';
import api from '../../../../../services/api';

import * as S from './styles';
import Select from '../../../../../components/Select';
import { IDevice } from '../../../../../interfaces';
import { HiOutlineTrash } from 'react-icons/hi';
import { FiAlertTriangle } from 'react-icons/fi';
import Slider from '../../../../../components/Slider';
import { AiOutlineClose } from 'react-icons/ai';

const Devices: React.FC = () => {
  const deviceFormRef = useRef<FormHandles>(null);
  const searchDeviceFormRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const { addToast } = useToast();

  const [deleteDeviceId, setDeleteDeviceId] = useState('');
  const [loader, setLoader] = useState(false);
  const [usersForDeviceList, setUsersForDeviceList] = useState<IUser[]>([] as IUser[]);
  const [deviceList, setDeviceList] = useState<IDevice[]>([] as IDevice[]);
  const [batteryEmailAlert, setBatteryEmailAlert] = useState(false);
  const [batteryAlert, setBatteryAlert] = useState<boolean>(true);

  const searchUsersForDeviceList = useCallback(async () => {
    const searchUser = deviceFormRef.current?.getFieldValue('user');

    try {
      const { data } = await api.get('profiles', { params: { searchUser } });

      if (user.role === 'project') {
        return setUsersForDeviceList([user, ...data]);
      }

      return setUsersForDeviceList(data);
    } catch (error) {
      return addToast({
        type: 'error',
        title: 'Erro ao buscar usuários',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    }
  }, [addToast]);

  const searchDeviceHandle = useCallback(async () => {
    const name = searchDeviceFormRef.current?.getFieldValue('searchDevice');
    try {
      const { data } = await api.get('devices', { params: { name } });

      setDeviceList(data);
    } catch (error) {
      return addToast({
        type: 'error',
        title: 'Erro ao buscar dispositivos',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    }
  }, [addToast]);

  const onDeleteDevice = async () => {
    try {
      await api.delete('devices', { params: { id: deleteDeviceId } });

      setDeleteDeviceId('');

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'O dispositivo foi deletado com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao deletar dispositivo',
        description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
      });
    }
  };

  const deviceFormHandler = useCallback(
    async formData => {
      setLoader(true);
      try {
        deviceFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required(),
          user: Yup.string().required(),
          mac: Yup.string().required(),
          battery_alert: Yup.boolean().required(),
          battery_email_alert: Yup.boolean().when('battery_alert', {
            is: true,
            then: Yup.boolean().required(),
            otherwise: Yup.boolean().optional(),
          }),
          battery_alert_limit: Yup.number()
            .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
            .when('battery_alert', {
              is: true,
              then: Yup.number().required(),
              otherwise: Yup.number().nullable(),
            }),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        if (!formData.user) {
          return addToast({
            type: 'error',
            title: 'Erro ao criar novo dispositivo',
            description: 'Você precisa atribuir o dispositivo para um usuário',
          });
        }

        const { name, user, mac, battery_alert, battery_email_alert, battery_alert_limit } = formData;
        const dataToSend = {
          name,
          user_id: user,
          mac_address: mac,
          battery_alert,
          battery_email_alert: battery_alert ? battery_email_alert : undefined,
          battery_alert_limit: battery_alert ? battery_alert_limit : undefined,
        };

        await api.post('/devices', dataToSend);

        deviceFormRef.current?.reset();

        addToast({
          type: 'success',
          title: 'Dispositivo criado!',
          description: 'Dispositivo criado com sucesso!',
        });

        setUsersForDeviceList([]);

        return searchDeviceHandle();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          return deviceFormRef.current?.setErrors(errors);
        }
        return addToast({
          type: 'error',
          title: 'Erro ao criar novo dispositivo',
          description: (error as AxiosError).response ? `${(error as AxiosError).response?.data.message}` : `${error}`,
        });
      } finally {
        searchDeviceHandle();

        setLoader(false);
      }
    },
    [addToast, searchDeviceHandle],
  );

  useEffect(() => {
    searchDeviceHandle();
    searchUsersForDeviceList();
  }, [searchDeviceHandle, searchUsersForDeviceList, deleteDeviceId]);

  const deviceForm = useMemo(() => {
    return (
      <Form ref={deviceFormRef} onSubmit={deviceFormHandler}>
        <h2>Criar novo dispositivo</h2>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} className={loader ? 'disabled' : ''}>
            <p>Nome</p>
            <Input hideErrorIcon name="name" type="text" />
          </Grid>

          <Grid item xs={12}>
            <p>Selecione um usuário</p>
            <Select name="user" data={usersForDeviceList} />
          </Grid>

          <Grid item xs={12} className={loader ? 'disabled' : ''}>
            <p>Endereço (mac do dispositivo)</p>
            <Input hideErrorIcon name="mac" type="text" className="mac" />
          </Grid>
        </Grid>
        <Grid>
          <S.CheckContainer>
            <p>Alerta da bateria</p>
            <Slider
              name="battery_alert"
              checked={batteryAlert}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBatteryAlert(e.target.checked);
              }}
            />
          </S.CheckContainer>
        </Grid>
        <Grid>
          <S.FormFieldsContainer height={batteryAlert ? 8 : 0}>
            <Grid item xs={12}>
              <p>Percentual limite de bateria</p>
              <Input name="battery_alert_limit" type="number" placeholder="15" showPercentage />
            </Grid>
            <Grid item xs={12}>
              <div className="slider-container">
                <p>Receber alerta por email</p>
                <Slider
                  name="battery_email_alert"
                  checked={batteryEmailAlert}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setBatteryEmailAlert(e.target.checked);
                  }}
                />
              </div>
            </Grid>
          </S.FormFieldsContainer>
        </Grid>
        <Grid>
          <Button className="submitNewDeviceButton">{loader ? 'Cadastrando ...' : 'Cadastrar'}</Button>
        </Grid>
      </Form>
    );
  }, [loader, deviceFormHandler, usersForDeviceList, batteryAlert]);

  const searchDeviceForm = useMemo(() => {
    return (
      <Form onSubmit={searchDeviceHandle} ref={searchDeviceFormRef} className="search">
        <h3>
          <BiSearchAlt />
          Buscar
          <span> dispositivos</span>
        </h3>

        <Input name="searchDevice" type="text" placeholder="Nome do dispositivo..." />
        <Button>pesquisar</Button>
      </Form>
    );
  }, [searchDeviceHandle]);

  const searchDevice = useMemo(() => {
    return (
      <div className="results">
        <h3>Resultado:</h3>
        <ul>
          {deviceList.length > 0 ? (
            deviceList.map(device => (
              <button type="button" key={device.id}>
                <p>{device.name}</p>
                <p>{device.user.name}</p>
                <span>
                  <Link to={`/app/device/${device.id}`}>
                    <FaEdit />
                  </Link>
                </span>
                <span onClick={() => setDeleteDeviceId(device.id)}>
                  <HiOutlineTrash />
                </span>
              </button>
            ))
          ) : (
            <h3 className="result">Nenhum dispositivo encontrado</h3>
          )}
        </ul>
      </div>
    );
  }, [deviceList]);

  return (
    <>
      {!deleteDeviceId && (
        <>
          <PageTitle sub="Dispositivos" title="Configurar" />
          <S.Container>
            <S.SearchDeviceForm show>{searchDeviceForm}</S.SearchDeviceForm>
            <S.SearchDevice show>{searchDevice}</S.SearchDevice>
            <S.AdminUpdateFormContainer>{deviceForm}</S.AdminUpdateFormContainer>
          </S.Container>
        </>
      )}
      {!!deleteDeviceId && (
        <S.DeletePopup>
          <div>
            <FiAlertTriangle size={30} />
            <p>Deseja deletar o dispositivo?</p>
            <div className="buttons">
              <Button className="cancel" onClick={() => setDeleteDeviceId('')}>
                Cancelar
              </Button>
              <Button className="delete" onClick={onDeleteDevice}>
                Deletar
              </Button>
            </div>
          </div>
        </S.DeletePopup>
      )}
    </>
  );
};

export default Devices;
