import React from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Container, Dot } from './styles';
import api from '../../../../../services/api';
import { Tooltip } from '@material-ui/core';
import { UserRolesEnum } from '../../../../../hooks/auth';

interface INotificationProps {
  onReadNotification: () => void;
  device_name: string;
  sensor_name?: string;
  sensor_id?: string;
  alert_id: string;
  read?: boolean;
  role: UserRolesEnum;
  type: 'sensor' | 'battery';
  device_id: string;
}

const Notification: React.FC<INotificationProps> = ({ onReadNotification, device_name, sensor_name, sensor_id, alert_id, read, role, type, device_id }) => {
  const { push } = useHistory();

  const readNotification = async () => {
    if (type === 'sensor') {
      try {
        await api.put('/alerts/read', {
          alerts_id: [alert_id],
        });

        onReadNotification();

        if (role !== UserRolesEnum.OPERATOR) {
          push(`/app/device/sensor/${sensor_id}`);
        }
      } catch (error) {}
    } else {
      try {
        await api.put('/battery/alerts/read', {
          alerts_id: [alert_id],
        });

        onReadNotification();

        if (role !== UserRolesEnum.OPERATOR) {
          push(`/app/device/${device_id}`);
        }
      } catch (error) {}
    }
  };

  return (
    <Tooltip title={!read ? 'Clique para ver a notificação' : ''}>
      <Container onClick={readNotification}>
        <strong>Alerta {`${type === 'battery' ? 'de bateria' : 'de sensor'}: `}</strong>
        <span>{`Dispositivo ${device_name}`}</span>
        {sensor_name && <span>{` - ${sensor_name}`}</span>}
        {!read && <Dot />}
      </Container>
    </Tooltip>
  );
};

export default Notification;
