import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface containerInterface {
  error: boolean;
}

export const Container = styled.div<containerInterface>`
  box-shadow: -5px 5px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 10px;
  display: flex;

  & select {
    flex: 1;
    background: transparent;
    border: none;
    color: #0c5c75;
    &::placeholder {
      color: #a7d1e1;
    }
  }
  ${props =>
    props.error &&
    css`
      background-color: transparent;
      border-color: #c53030;
    `}
`;

export const Error = styled(Tooltip)`
  display: flex;
  position: absolute;
  background-color: transparent;
  height: 40px;
  align-items: center;
  margin-left: -40px;
  max-width: 40px;
  padding-left: 10px;

  svg {
    margin: 0;
  }

  span {
    border: none;
    background: #c53030;
    color: #fff;
    margin-left: 160px;

    &::before {
      border-color: none;
    }
  }
`;
