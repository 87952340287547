import React, { ReactNode } from 'react';

import { useDropzone } from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  uploadData: (file: File[]) => void;
}

const Upload: React.FC<UploadProps> = ({ uploadData }) => {
  function renderDragMessage(isDragActive: boolean, isDragRejest: boolean): ReactNode {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Selecione ou arraste o arquivo aqui.
          <small>Apenas arquivos de imagem</small>
        </UploadMessage>
      );
    }

    if (isDragRejest) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({ onDropAccepted: uploadData, accept: 'image/*' });

  return (
    <>
      <DropContainer {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
        <input {...getInputProps()} data-testid="upload" />
        {renderDragMessage(isDragActive, isDragReject)}
      </DropContainer>
    </>
  );
};

export default Upload;
