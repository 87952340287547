import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

interface IData {
  id: string;
  name: string;
}

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  name: string;
  data: IData[];
  icon?: React.ComponentType<IconBaseProps>;
}

const Select: React.FC<SelectProps> = ({ name, data, ...rest }) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
      <Container className="container-input" error={!!error}>
        <select {...rest} ref={selectRef}>
          <>
            <option> </option>
            {data.map(d => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))}
          </>
        </select>
      </Container>
    </>
  );
};
export default Select;
