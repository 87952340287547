import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { UpdateFormContainer } from '../User/styles';

const appear = keyframes`
  from {
    opacity: 0;
    transform: translateZ(-100px)

  }
  to {
    opacity: 1;
    transform: translateZ(0px)

  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  animation: ${appear} 0.7s;
  padding: 10px 0px;

  & div.close {
    display: flex;
    height: 30px;
    width: 100%;
    margin-top: 0px;
    margin-left: 0;
    animation: ${appear} 0.7s;

    & button {
      background-color: transparent;
      display: flex;
    }

    & svg {
      color: #888888;
    }

    & p {
      color: #888888;
      font-size: 18px;
      font-weight: 500;
    }
  }

  & div.delete {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(138, 138, 138, 0.8);
    /* opacity: 0.5; */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${appear} 0.7s;

    & div {
      background-color: #ffffff;
      opacity: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 500px;
      height: 300px;
      border-radius: 20px;
      gap: 50px;

      & svg {
        color: #ff0000;
        scale: 3;
      }

      & p {
        font-size: 18px;
        font-weight: 800;
        color: #000000;
      }

      & div.buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;
        width: 100%;
        height: auto;

        & button.d {
          width: 35%;
          background-color: #ff0000;
        }
        & button.c {
          width: 35%;
          background-color: transparent;
          border: 2px solid #ff0000;
          color: #ff0000;
        }
      }
    }
  }
`;

export const AdminUpdateFormContainer = styled(UpdateFormContainer)`
  background-color: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90%;
  max-width: 1500px;
  margin: 10px;
  padding: 50px;
  border-radius: 15px;
  box-shadow: -5px 5px 5px ${shade(0.2, '#ececec')};
  gap: 50px;
  animation: ${appear} 0.7s;

  & form {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 20px;

    & div.upload {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 160px;
      height: 160px;

      & div {
        width: 160px;
        height: 160px;
      }

      & img {
        width: 160px;
        max-height: 150px;
        border-radius: 10px;
      }

      & svg {
        color: #ffffff;
        background-color: #ff0000;
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 5px;
        position: absolute;
        left: -10px;
        bottom: -20px;
        cursor: pointer;

        :hover {
          transition: background-color 200ms ease;
          background-color: ${shade(0.2, '#ff0000')};
        }
      }
    }

    & div.form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & div.input {
        gap: 20px;
        width: 90%;

        & p {
          font-size: 16px;
          color: #000000;
          font-weight: 500;
        }

        & div.input {
          & div.container-input {
            background-color: #ffffff;
          }
        }

        & div.disabled {
          border: none;
          div {
            background: #ececec;
            border: 2px solid #ececec;
            cursor: not-allowed;
            input {
              color: #b9b9b9;
              cursor: not-allowed;
            }
          }
        }
      }

      & button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 200px;
      }
    }

    & svg.trash {
      background-color: #ff0000;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      color: #ffffff;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      cursor: pointer;

      :hover {
        transition: background-color 200ms ease;
        background-color: ${shade(0.2, '#ff0000')};
      }
    }

    @media (max-width: 1240px) {
      flex-direction: column;
      height: 200px;

      & form {
        margin-top: 50px;
        margin-bottom: 10px;
        gap: 10px;
      }

      & svg {
        scale: 0.7;
      }
    }
  }

  @media (max-width: 1240px) {
    overflow: auto;
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: #ececec;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${shade(0.3, '#ececec')};
  }
`;

export const Register = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;

  & button.register {
    background-color: #09495d;
    color: #ffffff;
    display: flex;
    width: 200px;
    height: 50px;
    text-transform: uppercase;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    justify-content: space-around;
    align-items: center;
    padding: 0 15px;
    &:hover {
      background: ${shade(0.2, '#09495d')};
    }
  }

  @media (max-width: 1240px) {
    & button.register {
      scale: 0.8;
    }
  }
`;

export const ListUser = styled.div`
  width: 100%;
  overflow: auto;
  /* height: 600px; */
  padding: 30px 5px;

  & > ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;

    .userItem {
      margin-top: 1rem;
    }

    & > li {
      display: flex;
      flex-direction: column;

      ul.operatorList {
        padding-left: 2rem;
      }
    }

    .userItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;
      height: 70px;
      border-radius: 10px;
      background-color: #ececec;
      box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25);

      & svg {
        height: 30px;
        width: 30px;

        :hover {
          transition: all 200ms;
          color: #1b9eb2;
          cursor: pointer;
        }
      }

      & p {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
      }

      & p.name {
        width: 35%;
      }

      & p.email {
        width: 35%;
      }

      & p.role {
        width: 15%;
      }

      & img {
        width: 60px;
        max-height: 50px;
        border-radius: 5px;
      }

      & img.default {
        filter: blur(1px);
      }

      @media (max-width: 1240px) {
        gap: 10px;
        padding: 0 5px;

        & svg {
          height: 20px;
        }

        & p.name {
          width: 40%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & p.email {
          width: 40%;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        & p.role {
          display: none;
        }
      }
    }
  }

  & div.doubt {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    & img {
      width: 150px;
    }
    & h2 {
      color: #0c5c75;
      font-size: 2rem;
      font-weight: bold;
    }

    @media (max-width: 1240px) {
      & img {
        width: 100px;
      }
      & h2 {
        font-size: 1em;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: #ececec;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${shade(0.3, '#ececec')};
  }

  height: 100%;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
`;
