import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { BiSearchAlt } from 'react-icons/bi';
import { MdPlayArrow, MdEmail } from 'react-icons/md';

import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { IUser } from '../../../../hooks/auth';
import { useSelectInfo } from '../../../../hooks/selectInfo';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { Container, Triangle, Content } from './styles';

interface ISearchUserParams {
  searchUser?: string;
}

const AdminToolContent: React.FC = () => {
  const searchUserRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { selectedUser, selectUser, selectDevice } = useSelectInfo();

  const [showAdminTool, setShowAdminTool] = useState(false);
  const [searchUserMethod, setSeachUserMethod] = useState('all');
  const [userList, setUserList] = useState<IUser[]>([] as IUser[]);

  const searchUsersList = useCallback(
    async ({ searchUser }: ISearchUserParams) => {
      try {
        const { data } = await api.get('profiles', { params: { searchUser } });

        return setUserList(data);
      } catch (error) {
        return addToast({
          type: 'error',
          title: 'Erro ao buscar usuários',
          description: error.response ? `${error.response.data.message}` : `${error}`,
        });
      }
    },
    [addToast],
  );

  const selectInfos = useCallback(
    ({ user, deviceId }) => {
      selectUser(user);
      selectDevice(deviceId);
    },
    [selectUser, selectDevice],
  );

  const searchUserHandle = useCallback(async () => {
    const searchUser = searchUserRef.current?.getFieldValue('searchUser');

    return searchUsersList({ searchUser: searchUser || '' });
  }, [searchUsersList]);

  const searchUserList = useMemo(() => {
    if (userList?.length) {
      return userList.map(user => {
        return (
          <button
            type="button"
            key={user.id}
            className={selectedUser?.id === user.id ? 'active' : ''}
            onClick={() => {
              selectInfos({ user, deviceId: user?.devices[0]?.id });
            }}
          >
            <div>
              <p>
                {user.name}
                <br />
                <small>{user.email}</small>
              </p>
            </div>
            <div>
              <MdPlayArrow />
            </div>
          </button>
        );
      });
    }
    return <h3>Nenhum usuario encontrado</h3>;
  }, [userList, selectInfos, selectedUser]);

  useEffect(() => {
    searchUsersList({ searchUser: '' });
  }, [searchUsersList]);

  return (
    <Container>
      <Triangle isShow={showAdminTool} onClick={() => setShowAdminTool(!showAdminTool)} />

      <Content show={showAdminTool}>
        <h2>Usuarios</h2>
        <Form onSubmit={searchUserHandle} ref={searchUserRef}>
          <h3>
            <BiSearchAlt />
            Buscar
            <span> usuários</span>
          </h3>
          <ul>
            <button type="button" onClick={() => setSeachUserMethod('all')}>
              <li className={searchUserMethod === 'all' ? 'active' : ''}>Todos</li>
            </button>
            <button type="button" onClick={() => setSeachUserMethod('id')}>
              <li className={searchUserMethod === 'id' ? 'active' : ''}>Por nome</li>
            </button>
          </ul>
          {searchUserMethod === 'id' && <Input placeholder="Digite o nome..." name="searchUser" type="text" />}
          <Button>Buscar</Button>
        </Form>

        <div className="results">
          <h3>Resultado</h3>
          <ul className="searchUserList">{searchUserList}</ul>
        </div>
      </Content>
    </Container>
  );
};
export default AdminToolContent;
