import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { GiExitDoor, GiRadioTower } from 'react-icons/gi';
import { GoGear, GoPerson } from 'react-icons/go';
import { HiUserCircle } from 'react-icons/hi';
import { RiDoorOpenFill } from 'react-icons/ri';
import { MdOutlineConnectedTv, MdOutlineDataSaverOff } from 'react-icons/md';
import { VscBell } from 'react-icons/vsc';
import { Badge, Popover, Tooltip } from '@material-ui/core';
import { UserRolesEnum, useAuth, IUser } from '../../../hooks/auth';
import Routes from '../routes';

import logo from '../../../assets/logo.png';

import { Container, Content, Aside } from './styles';

import AdminToolContent from './AdminToolContent';
import NotificationsPopUp from '../components/NotificationsPopUp';

const Layout: React.FC = () => {
  const { signOut, user } = useAuth();
  const history = useHistory();
  const location = history.location.pathname;
  const [signOutPopUpAnchor, setSignOuPopUpAnchor] = useState<Element | null>(null);
  const [notificationsPopUpAnchor, setNotificationsPopUpAnchor] = useState<Element | null>(null);
  const [notification, setNotification] = useState(0);

  const isSignOutPopUpOpen = Boolean(signOutPopUpAnchor);
  const isNotificationsPopUpOpen = Boolean(notificationsPopUpAnchor);

  const handleCloseSignOutPopUp = (): void => setSignOuPopUpAnchor(null);
  const handleCloseNotificationsPopUp = (): void => setNotificationsPopUpAnchor(null);

  const UserLogo = (
    <div>
      {user.avatar_url ? (
        <img src={user.avatar_url || ''} alt="logo" className="logo" />
      ) : user.manager && user.manager?.avatar_url ? (
        <img src={user.manager.avatar_url || ''} alt="logo" className="logo" />
      ) : (
        <img src={logo || ''} alt="logo" className="logo" />
      )}
    </div>
  );

  return (
    <>
      <Aside isAdmin={user.role === UserRolesEnum.ADMIN}>
        <ul>
          <li>
            <Tooltip title="Dashboard">
              <Link to="/app/dashboard">
                <MdOutlineDataSaverOff className={location === '/app/dashboard' ? 'active' : ''} />
              </Link>
            </Tooltip>
          </li>
          {user.role !== UserRolesEnum.OPERATOR && (
            <li>
              <Tooltip title="Dispositivos">
                <Link to="/app/device">
                  <MdOutlineConnectedTv className={location === '/app/device' ? 'active' : ''} />
                </Link>
              </Tooltip>
            </li>
          )}
          <li>
            <Tooltip title={user.role === UserRolesEnum.OPERATOR ? 'Perfil' : 'Usuários'}>
              <Link to="/app/user">
                <HiUserCircle className={location === '/app/user' ? 'active' : ''} />
              </Link>
            </Tooltip>
          </li>
        </ul>
        <footer>
          {/* A estilização do Popover está no global.ts, pois o MUI cria um Portal */}
          <Popover
            open={isSignOutPopUpOpen}
            anchorEl={signOutPopUpAnchor}
            onClose={handleCloseSignOutPopUp}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <div className="exitPopUp">
              <p>Deseja sair?</p>
              <section>
                <button type="button" onClick={handleCloseSignOutPopUp}>
                  Cancelar
                </button>
                <button type="button" onClick={() => signOut()}>
                  Sair
                </button>
              </section>
            </div>
          </Popover>
          <Tooltip title="Fazer log out" arrow placement="top">
            <button type="button" onClick={event => setSignOuPopUpAnchor(event.currentTarget)}>
              <RiDoorOpenFill />
            </button>
          </Tooltip>
        </footer>
      </Aside>

      <Container>
        {user.role !== UserRolesEnum.OPERATOR && location === '/app/dashboard' && <AdminToolContent />}
        <div className="appContent">
          <header>
            {UserLogo}
            <div className="headerUser">
              <p>{`Olá, ${user.name}`}</p>
              <NotificationsPopUp
                setNotification={setNotification}
                anchor={notificationsPopUpAnchor}
                handleClose={handleCloseNotificationsPopUp}
                isOpen={isNotificationsPopUpOpen}
              />
              <Tooltip title="Notificações">
                <button type="button" className="notificationButton" onClick={event => setNotificationsPopUpAnchor(event.currentTarget)}>
                  <Badge style={{ zIndex: 0 }} color="error" badgeContent={notification}>
                    <VscBell size={20} />
                  </Badge>
                </button>
              </Tooltip>
            </div>
          </header>
          <Content>
            <Routes />
          </Content>
        </div>
      </Container>
    </>
  );
};

export default Layout;
