import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const Slider: React.FC<InputProps> = ({ name, defaultChecked, onChange, checked, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);
  const [toogle, setToogle] = useState(defaultChecked || checked || false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (checked) setToogle(checked);
  }, [checked]);

  return (
    <>
      <Container className="container-input">
        <input
          {...rest}
          ref={inputRef}
          type="checkbox"
          checked={toogle}
          onChange={e => {
            setToogle((prev: boolean) => !prev);
            if (onChange) onChange(e);
          }}
        />
        <span />
      </Container>
    </>
  );
};
export default Slider;
